import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';

import { Globals } from '../shared/globals';

// import { OrganisationUnit} from '../peppi-lum/organisation-unit';
import { UnitService} from './unit.service';
import { NavbarService } from '../my-navbar/navbar.service';
// import { LumService } from '../peppi-lum/lum.service';
import { OrganisationService } from '../organisation/organisation.service';
import * as _ from 'lodash';
import { Programme } from '../peppi-lum/programme';
import { MarkdownComponent } from '../shared/markdown.component'


@Component({
  selector: 'education-description',
  styleUrls: ['./education-description.component.css'],
  template: `<div *ngFor="let description of descriptions" >
  <div *ngIf="globals.getLocalizedValue(description?.text)">
  <!--<div *ngIf="description.value.length > 0" class="education-content">-->
    <div *ngIf="description.text.valueFi.length > 0 || description.text.valueSv.length > 0 || description.text.valueEn.length > 0" class="education-content">
      <div>
          <b>{{globals.getLocalizedValue(description.descriptionField.title)}}</b>
          <p>
            <app-markdown [body]="globals.getLocalizedValue(description?.text)">

            </app-markdown>
          </p>
      </div>
    </div>
  </div>
</div>`
})
export class EducationDescriptionComponent implements OnInit {

  //@Input() id: string;
  @Input() descriptions: Object[];
 // errorMessage: string;

  constructor(
   // private organisationService: OrganisationService,
    private globals: Globals
  ) {}

  ngOnInit() {
    /*this.organisationService.getEducationDescriptions(this.id).subscribe(
      descriptions => {
        this.descriptions = descriptions;
      },
      error => this.errorMessage = <any>error
    );*/
  }
}

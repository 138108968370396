import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FrontPageComponent } from './front-page.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { MaterializeModule } from 'angular2-materialize';


const frontPageRoutes: Routes = [
   // { path: '', component: FrontPageComponent },
    { path: ':lang/index', pathMatch: 'full', component: FrontPageComponent}
];

@NgModule({
    imports: [
        RouterModule.forChild(frontPageRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class FrontPageRoutingModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterializeModule } from 'angular2-materialize';
import { NgxMdModule as MarkdownModule } from 'ngx-md';

import { SharedModule } from '../shared/shared.module';

import { EducationDescriptionComponent } from './education-description.component';



@NgModule({
  imports: [
    CommonModule,
    MaterializeModule,
    MarkdownModule.forRoot(),
    SharedModule
  ],
  declarations: [
    EducationDescriptionComponent
  ],
  providers: [],
  exports: [
    EducationDescriptionComponent
  ]
})
export class EducationDescriptionModule { }

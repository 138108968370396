import { Component, OnInit, Input, OnDestroy, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { flatMap, map } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { Globals } from '../shared/globals';
import { environment } from '../../environments/environment';
import { NavbarService } from '../my-navbar/navbar.service';
import * as _ from 'lodash';
import { LocalizedString } from '../course/localized-string';
import { Title } from '@angular/platform-browser';
import { OrganisationService } from '../organisation/organisation.service';
import { Organisation } from '../organisation/organisation';
import { MaterializeAction } from 'angular2-materialize';


@Component({
  selector: 'app-education-type',
  templateUrl: './education-type.component.html',
  styleUrls: ['./education-type.component.css']
})
export class EducationTypeComponent implements OnInit, OnDestroy {

  organisation: Organisation;
  educationTypes: [Object];
  isLoaded = false;
  langChangedSubscription: any;
  organisationSubscription: any;
  educationTypeDescriptionSubscription: any;
  id: any;
  period: any;
  private subscription: Subscription = new Subscription();
  actions: EventEmitter<any|MaterializeAction> = new EventEmitter <any|MaterializeAction>();

  constructor(
    private globals: Globals,
    private route: ActivatedRoute,
    private router: Router,
    private navigationService: NavbarService,
    private titleService: Title,
    private organisationService: OrganisationService
  ) {}

  ngOnInit() {

    this.subscription.add(combineLatest(this.route.paramMap, this.route.queryParamMap).pipe(
      map(params => ({
        id: params[0].get('id'), 
        period: params[1].get('period')
      })),
      // haetaan jakso
      flatMap(params => { 
        this.id = params.id;
        this.period=params.period;
        return this.organisationService.getOrganisation(this.id);
      }),
      flatMap(organisation => { 
        this.organisation = organisation;
        this.setTitle();
        return this.organisationService.getEducationTypesByOrganisation(this.id);
      })
      ).subscribe( educationTypes => {
        this.educationTypes = educationTypes;
        this.setUrl();
        this.isLoaded = true;
      },
      error => console.log(<any>error)
      ));

      // tällä alustetaan kielivalinta sovellukselle urlista
    this.subscription.add(this.route.params.subscribe(params => {
      if (_.isEqual(params['lang'], 'fi')) {
        this.globals.lang = 'fi'
      }
      if (_.isEqual(params['lang'], 'en')) {
        this.globals.lang = 'en'
      }
      if (_.isEqual(params['lang'], 'sv')) {
        this.globals.lang = 'sv'
      }

  }));

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  collapse(event, i) {
    if (event.key === "Enter") {
      this.actions.emit({action:"collapsible", params:['open', i]});
    }
  }

  setTitle() {
    if (this.period) {
      this.titleService.setTitle(this.globals.getLocalizedValue(this.organisation.name) + environment.titleExtension + ' ' + this.period);
    } else {
      this.titleService.setTitle(this.globals.getLocalizedValue(this.organisation.name) + environment.titleExtension);
    }
  }

  setUrl() {
    if (!this.period) {
      this.globals.currentUrl = {
        valueFi: '/fi/' + this.globals.getLocalizedValue(this.organisation.name) + '/' + this.id + '/e', 
        valueEn: '/en/' + this.globals.getLocalizedValue(this.organisation.name) + '/' + this.id + '/e',
        valueSv: '/sv/' + this.globals.getLocalizedValue(this.organisation.name) + '/' + this.id + '/e'
      };
    } else {            
      this.globals.currentUrl = {
        valueFi: '/fi/' + this.globals.getLocalizedValue(this.organisation.name) + '/' + this.id + '/e' + '?period=' + this.period,
        valueEn: '/en/' + this.globals.getLocalizedValue(this.organisation.name) + '/' + this.id + '/e' + '?period=' + this.period,
        valueSv: '/sv/' + this.globals.getLocalizedValue(this.organisation.name) + '/' + this.id + '/e' + '?period=' + this.period
      }
    }
    // asetetaan canonical
    
    this.globals.createLinkForCanonicalURL();
  }
}

import { Component, Input, OnInit, EventEmitter, ViewChild, OnDestroy  } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { LumService } from '../peppi-lum/lum.service';
import { Globals } from '../shared/globals';
import * as _ from 'lodash';
import {MaterializeAction} from 'angular2-materialize';
import { CourseUnit } from './../course/course-unit';
import { CourseUnitRealization } from './../course/course-unit-realization';
import { CourseService } from './../course/course.service';
import { LottaApiService } from '../lotta-api/lotta-api.service';
import { LocalizedString } from './../course/localized-string';
import { MarkdownComponent } from '../shared/markdown.component'
import { TextService } from '../shared/text.service';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { OrganisationService } from '../organisation/organisation.service';
import { Observable } from 'rxjs';
//import { EducaEducationD

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.css']
})
export class ArchiveComponent implements OnInit, OnDestroy {

  year: number;
  isLoaded = false;
  errorMessage: string;
  educations: any;

  // subscriptions
  educationSubscription: any;
  langChangedSubscription: any;
  langSelectionSubscription: any;
  currentUrlSubscription: any;

  constructor(
    // private lumService: LumService,
    private lottaApiService: LottaApiService,
    private organisationService: OrganisationService,
    private globals: Globals,
    private route: ActivatedRoute,
    private router: Router,
    private textService: TextService,
    private titleService: Title
    ) {
      this.globals.langChanged.subscribe(lang => this.onLangChanged(lang));
    }

  ngOnInit(): void {
    this.setCurrentUrl();

    this.educationSubscription = this.route.params.pipe(
      switchMap((params: Params) =>  this.organisationService.getEducationListBySchoolYear(params['year'])))
      .subscribe((educations: Observable<any>[]) => {
        this.educations = educations;
        this.globals.sortByName2(this.educations, this.globals.lang);
        this.educations.forEach(education => {
          // tutkinto-ohjelmat järjestykseen
          this.globals.sortByName2(education.children, this.globals.lang);
        })
        this.isLoaded = true;
      });

      // tällä alustetaan kielivalinta sovellukselle urlista
    this.route.params.subscribe(params => {
      if (_.isEqual(params['lang'], 'fi')) {
        this.globals.lang = 'fi'
      }
      if (_.isEqual(params['lang'], 'en')) {
        this.globals.lang = 'en'
      }
      if (_.isEqual(params['lang'], 'sv')) {
        this.globals.lang = 'sv'
      }

    });

    this.route.params.subscribe(params => {
      this.year = +params['year'];
      this.globals.setSelectedSchoolYear(this.year);
    });

  }

  ngOnDestroy(): void {
    this.educationSubscription.unsubscribe();
  //  this.langChangedSubscription.unsubscribe();
  //  this.langSelectionSubscription.unsubscribe();
  //  this.currentUrlSubscription.unsubscribe();
  }

  setCurrentUrl() {
    // asetetaan url
    this.currentUrlSubscription = this.route.params.subscribe(params => {
      this.globals.currentUrl = {
        valueFi: '/fi/' +  'arkisto/' + params['year'],
        valueEn: '/en/' + 'archive/' + params['year'] ,
        valueSv: '/sv/' + 'archive/' + params['year']
      };
    });
    this.globals.createLinkForCanonicalURL();
  }

  private onLangChanged(lang: string): void {
   // console.log('lang has changed to ' + lang);
    this.globals.sortByName2(this.educations, lang);
    this.educations.forEach(education => {
      this.globals.sortByName2(education.children, this.globals.lang);
    });
    this.titleService.setTitle('Koulutukset 2017-2018' + environment.titleExtension);
  }
}

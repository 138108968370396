import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module'
import { MaterializeModule } from 'angular2-materialize';
import { RouterModule, Routes } from '@angular/router';
import { CourseComponent } from './course.component';
import { CourseService } from './course.service';
import { ProgrammeComponent } from './programmes.component';
import { RealizationComponent } from './realization.component';

const routes: Routes = [
   { path: ':lang/course/:name/:id', component: CourseComponent },
   { path: ':lang/opintojakso/:name/:id', component: CourseComponent },
   { path: ':lang/kurs/:name/:id', component: CourseComponent },
   { path: 'fi/jakso/:id', component: CourseComponent }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        SharedModule,
        MaterializeModule
    ],
    exports: [
        RouterModule
    ],
    providers: [CourseService],
    declarations: [
      CourseComponent,
      ProgrammeComponent,
      RealizationComponent
    ]
})
export class CourseRoutingModule { }

import { Component, Input, OnInit, OnChanges, SimpleChanges  } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Globals } from '../shared/globals';


import * as _ from 'lodash';

import { Programme } from '../peppi-lum/programme';
// import { MarkdownService } from 'ngx-md';


@Component({
  selector: 'programme-unit',
  templateUrl: './programme-unit.component.html',
  styleUrls: ['./programme.component.css']
})
export class ProgrammeUnitComponent   {

  @Input('unit') units: any;
  @Input('selection') selection: number;
  @Input('year') selectedSchoolYear: number;
  @Input('learning-path') learningPath : string;
  @Input('period') period: string;

  constructor(
    private globals: Globals
    
  ) {}

  filterAccomplishmentPlanSemesterList(accomplishmentPlanSemesterList) {
    return accomplishmentPlanSemesterList.slice(this.selectedSchoolYear - 1, accomplishmentPlanSemesterList.length);
  }

  filterAccomplishmentPlanList(accomplishmentPlanList) {
    return accomplishmentPlanList.slice(this.selectedSchoolYear - 1, accomplishmentPlanList.length);
  }

  /*filterAccomplishmentPlanPeriodList(accomplishmentPlanPeriodList) {
    return accomplishmentPlanPeriodList.slice((this.selectedSchoolYear * 5 - 5), this.selectedSchoolYear * 5);
  }*/

  filterAccomplishmentPlanPeriodList(accomplishmentPlanPeriodList, accomplishmentPlanYearList) {
    let periodCount = Math.ceil(accomplishmentPlanPeriodList.length / accomplishmentPlanYearList.length);
    console.log(periodCount);
    return accomplishmentPlanPeriodList.slice((this.selectedSchoolYear * periodCount - periodCount), this.selectedSchoolYear * periodCount);
  }


  includesLearningPath(unit) {
    // palautetaan true, jos ei opintopolkuja valu
    return this.learningPath ? unit.learningPathIds.includes(this.learningPath) : true;
  }

}

import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';

import * as _ from 'lodash';
import { Globals } from '../shared/globals';
import {MaterializeAction} from 'angular2-materialize';
import { NavbarService } from '../my-navbar/navbar.service';
import { LocalizedString } from '../course/localized-string';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'top-navbar',
  styleUrls: ['./top-navbar.component.css'],
  template: `

  <nav class="no-print hide-on-med-and-down">

  <div class="nav-wrapper">

    <ul id="nav-mobile" class="right hide-on-med-and-down">
      <li  *ngFor="let link of links">
        <a href = "{{globals.getLocalizedValue(link.url)}}">{{globals.getLocalizedValue(link.name)}}</a>
      </li>

      <li *ngFor="let lang of globals.languages">
        <a *ngIf="lang" lang="{{lang}}" (click)="changeLang(lang)" [ngClass]="{'light-blue darken-3': lang===globals.lang}" tabindex="0" (keydown)="changeLang2($event, lang)">{{lang | uppercase}}</a>
      </li>

    </ul>

  </div>
</nav>
  `
})
export class TopNavbarComponent implements OnInit  {

  errorMessage: string;
  links = [];
  currentUrl: LocalizedString;
  constructor(
    public globals: Globals,
    private route: ActivatedRoute,
    private router: Router,
    private navbarService: NavbarService
    //private _platformStrategy: PlatformLocation
  ) { }


  ngOnInit() {
    this.getLinks();
    this.currentUrl = this.globals.currentUrl;
  }

  getLinks() {
    this.navbarService.getLinks().subscribe(
      links => this.links = links,
      error => this.errorMessage = <any>error
    );
  }

  doNothing(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      //this.actions.emit({action:"dropdown", params:['open']}); 
    } 
  }

  getKebabCase(string: string) {
    return _.kebabCase(string);
  }
  changeLang(lang: string) {
      this.globals.setLang(lang);
      /**
      * Changes the browsers URL to the normalized version of the given URL, and pushes a
      * new item onto the platform's history.
      */
      // tämä vanha tapa ei tee sivulatausta, mutta aiheuttaa ongelmia lukuvuoden vaihtamisen kanssa
      // mikäli routeriin ei saada tietoa nykyisestä urlista 
      //this._platformStrategy.pushState(null, '', this.globals.getCurrentUrl(lang));
      //console.log('kielivaihdon url: ' + this.globals.getCurrentUrl(lang))
      //this.router.navigateByUrl(this.globals.getCurrentUrl(lang)); 
      //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigateByUrl(this.globals.getCurrentUrl(lang)); 
      
      //window.location.href = this.globals.getCurrentUrl(lang);
  }

  changeLang2(event, lang: string) {
    if (event.key === "Enter") {
      this.changeLang(lang);
    }
  }


}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LottaApiService } from '../lotta-api/lotta-api.service';
import { Programme } from './programme';

@Injectable()
export class ProgrammeService {
  constructor(private api: LottaApiService) { }

  getProgrammes(unitId: string): Observable<Programme[]> {
    return this.api.getList<Programme>(`programme/${unitId}/list`);
  }
  getProgrammeDescription(unitId: string): Observable<Object[]> {
    return this.api.getList<Object>(`programme/${unitId}/description`);
  }
  getProgrammeWithLearningUnit(unitId: string): Observable<Programme[]> {
    // return this.api.getOne<Programme[]>('lu', unitId);
    return this.api.getOne<Programme[]>('accomplishment-plan', unitId);
  }
  getRelatedProgrammes(id: string): Observable<Object[]> {
    return this.api.getList<Object>(`programme/course/${id}`);
  }
  getLearningPaths(id: string) : any {
    return this.api.getList<any>(`learning-path/${id}`);
  }
  getAccomplishmentPlan(accomplishmentPlanId: string) : any {
    return this.api.getList<any>(`accomplishment-plan/plan/${accomplishmentPlanId}`);
  }
  getAccomplishmentPlanList(id: string) : any {
    return this.api.getList<any>(`accomplishment-plan/list/${id}`);
  }
}

import { Component, OnInit, Input, OnDestroy, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { TextService } from '../shared/text.service';
import { ProgrammeService } from '../programme/programme.service';
import { Globals } from '../shared/globals';
import { OrganisationService } from '../organisation/organisation.service';
import { MaterializeAction } from 'angular2-materialize';

@Component({
  selector: 'app-education',
  styleUrls: ['./education-type.component.css'],
  // styles: ['li {list-style-type: square !important; list-style-position: inside; padding-left: 28px'],
  templateUrl: './education.component.html',
})
export class EducationComponent implements OnInit, OnDestroy {

  @Input() id: string;
  @Input() educationType: string;
  @Input() code: string;
  isLoaded = false;
  educations: any;
  errorMessage: string;
 // programmesHeader: LocalizedString;
  // programmes: Object[];
 // @Input('education-type') educationType: string;
  educationSubscription: any;
  actions: EventEmitter<any|MaterializeAction> = new EventEmitter <any|MaterializeAction>();


  constructor(
    private globals: Globals,
    private organisationService: OrganisationService,
    private textService: TextService
   // private educationService: Edu
  ) { this.globals.langChanged.subscribe(lang => this.onLangChanged(lang));
    }

    private onLangChanged(lang: string): void {
     // console.log('lang has changed to ' + lang);
      this.globals.sortByName2(this.educations, lang);
      this.educations.forEach(education => {
        this.globals.sortByName2(education.children, this.globals.lang);
      })
    }

    collapse(event, i) {
      if (event.key === "Enter") {
        event.stopPropagation();
        this.actions.emit({action:"collapsible", params:['open', i]});
      }
    }


  ngOnInit(): void {
     /**
     *  Koulutusten hakeminen
     */
    this.educationSubscription = this.organisationService.getEducations(this.id, this.educationType)
      .subscribe((educations) => {
        this.educations = educations;
        this.globals.sortByName2(this.educations, this.globals.lang);
        this.educations.forEach(education => {
          // tutkinto-ohjelmat järjestykseen
          this.globals.sortByName2(education.children, this.globals.lang);
        })
        this.isLoaded = true;
      });
  }

  ngOnDestroy(): void {
    this.educationSubscription.unsubscribe();
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterializeModule } from 'angular2-materialize';
import { NgxMdModule as MarkdownModule } from 'ngx-md';
import { LottaApiService } from './lotta-api/lotta-api.service';
import { LumService } from './peppi-lum/lum.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CourseRoutingModule } from './course/course-routing.module';
import { StudyModuleRoutingModule } from './study-module/study-module-routing.module';
import { UnitModule } from './unit/unit.module';
import { SharedModule } from './shared/shared.module';
import { OrganisationModule } from './organisation/organisation.module';
import { NavbarModule } from './my-navbar/navbar.module';
import { ProgrammeModule } from './programme/programme.module';
import { SearchModule } from './search/search.module';
import { MyNavbarComponent } from './my-navbar/my-navbar.component';
import { TopNavbarComponent } from './top-navbar/top-navbar.component';
import { Globals } from './shared/globals';
import { FormsModule } from '@angular/forms';
import { EducationTypeModule } from './education-type/education-type.module';
import { FrontPageModule } from './front-page/front-page.module';
import { ArchiveRoutingModule } from './archive/archive-routing.module';
import { EducationDescriptionModule } from './education-description/education-description.module';
import { NotFoundRoutingModule } from './not-found/not-found-routing.module';
import { HttpErrorInterceptor } from './http-error.interceptor';

@NgModule({
  imports: [
    MaterializeModule,
    MarkdownModule.forRoot(),
    BrowserModule,
    // HttpModule,
    HttpClientModule,
    FormsModule,
    StudyModuleRoutingModule,
    CourseRoutingModule, // tämä ennen organisationModulea
    ArchiveRoutingModule,
    NotFoundRoutingModule,
    EducationDescriptionModule,
    OrganisationModule,
    EducationTypeModule,
    NavbarModule,
    ProgrammeModule,
    SearchModule,
    UnitModule,
    SharedModule,
    FrontPageModule,
    AppRoutingModule // Pidä tämä viimeisenä, sillä muutoin alireitit hajoavat!
  ],
  declarations: [
    AppComponent,
    MyNavbarComponent,
    TopNavbarComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { map, flatMap } from 'rxjs/operators';
import { Globals } from '../../shared/globals';
import { environment } from '../../../environments/environment';
import { NavbarService } from '../../my-navbar/navbar.service';
import * as _ from 'lodash';
import { OrganisationService } from '../organisation.service';
import { Organisation } from '../organisation';
import { Title } from '@angular/platform-browser';
import { LocalizedString } from 'app/course/localized-string';
import { Subscription, combineLatest } from 'rxjs';

@Component({
  selector: 'my-organisation-list',
  templateUrl: './organisation-list.component.html',
  styleUrls: ['./organisation-list.component.css']
})
export class OrganisationListComponent implements OnInit, OnDestroy {

  errorMessage: string;
  organisations: Organisation[];
  isLoaded = false;
  @Input() children: Organisation[];
  @Input('unit') id: string;
  @Input('organisation-unit') organisaatioyksikko: string;
  @Input('education-type') educationType: string;
  period: string;
  frontPageRoute: LocalizedString = environment.frontPageRoute;
  header: string;
  parent: any;
  params: any;

  // subscriptions
  private subscription: Subscription = new Subscription();
 
  constructor(
    private globals: Globals,
    private route: ActivatedRoute,
    private router: Router,
    private navigationService: NavbarService,
    private titleService: Title,
    private service: OrganisationService
  ) {
    this.subscription.add(this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    }));

  }

  initialiseInvites() {
    // Set default values and re-fetch any data you need.
    // tällä saadaan aikaan latauspalkki
    this.subscription.add(this.route.url.subscribe(url => {
      this.isLoaded = false;
    }));
    this.ngOnInit();
  }

  ngOnInit() {    

    // Tänne tullaan vain ensimmäisellä kierroksella, kun rakennetta loopataan*/
    this.getOrganisations();
    this.getParent();

    // tällä alustetaan kielivalinta sovellukselle urlista
    this.subscription.add(this.route.params.subscribe(params => {
      if (_.isEqual(params['lang'], 'fi')) {
        this.globals.lang = 'fi'
      }
      if (_.isEqual(params['lang'], 'en')) {
        this.globals.lang = 'en'
      }
      if (_.isEqual(params['lang'], 'sv')) {
        this.globals.lang = 'sv'
      }
    }));
  } // ngOnInit ends

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Palautetaan vain ensimmäinen kerros organisaatiopuusta, jotta saataisiin se indeksoitua järkevämmin.
   */
  getOrganisations() {
    this.subscription.add(this.service.getOrganisations().subscribe(
      value => {
        this.organisations = value;
        this.sortOrganisations(this.organisations, this.globals.lang);
        this.isLoaded = true;
      },
      error => this.errorMessage = <any>error
    ));
  }

  // järjestää tiedekunnat aakkosittain
  sortOrganisations(organisations: Array<Organisation>, lang: string) {
    if ( _.isEqual(lang, 'fi')) {
      organisations.sort((a, b) => {
        if (a.name.valueFi < b.name.valueFi) {
          return -1;
        } else if (a.name.valueFi > b.name.valueFi) {
         return 1;
        } else {
          return 0;
        }
      });
    }
    if ( _.isEqual(lang, 'en')) {
      organisations.sort((a, b) => {
        if (a.name.valueEn < b.name.valueEn) {
          return -1;
        } else if (a.name.valueEn > b.name.valueEn) {
          return 1;
        } else {
          return 0;
        }
      });
      return organisations;
    }
    if ( _.isEqual(lang, 'sv')) {
      organisations.sort((a, b) => {
        if (a.name.valueSv < b.name.valueSv) {
          return -1;
        } else if (a.name.valueSv > b.name.valueSv) {
          return 1;
        } else {
          return 0;
        }
      });
      return organisations;
    }
  }

  /**
   * Palauttaa sivua vastaavan koulutustyypin tiedot sivun otsikkoa
   * varten (parent), sekä puurakenteen linkityksiä varten (educationType)
   */
  getParent() {
    this.subscription.add(combineLatest(this.route.paramMap, this.route.queryParamMap).pipe(
      map(params => ({
        id: params[0].get('id'), 
        period: params[1].get('period')
      })),
      flatMap((params) => {
          this.id = params.id;
          this.period = params.period;
          return this.navigationService.getNavigationItem((this.id));     
      })
    ).subscribe(value => {
      this.parent = value;
      this.educationType = this.parent.id;
      this.setCurrentUrl();
      this.setTitle();
    }, error => this.errorMessage = <any>error));
  }

  setTitle() {
    if (this.period) {
      this.titleService.setTitle(this.globals.getLocalizedValue(this.parent.name) + environment.titleExtension + ' ' + this.period);
    } else {
      this.titleService.setTitle(this.globals.getLocalizedValue(this.parent.name) + environment.titleExtension);
    }
  }

  setCurrentUrl() {
    if (this.parent) {
        if(!this.period) {
          this.globals.currentUrl = {valueFi: '/fi/' + this.getKebabCase(this.parent.name.valueFi) + '/' + this.educationType + '/o', //params['id']
          valueEn: '/en/' + this.getKebabCase(this.parent.name.valueEn) + '/' + this.educationType + '/o',
          valueSv: '/sv/' + this.getKebabCase(this.parent.name.valueEn) + '/' + this.educationType + '/o'};
      } else {
          this.globals.currentUrl = {valueFi: '/fi/' + this.getKebabCase(this.parent.name.valueFi) + '/' + this.educationType + '/o' + '?period=' + this.period, //qparams['period']
          valueEn: '/en/' + this.getKebabCase(this.parent.name.valueEn) + '/' + this.educationType + '/o' + '?period=' + this.period,
          valueSv: '/sv/' + this.getKebabCase(this.parent.name.valueEn) + '/' + this.educationType + '/o' + '?period=' + this.period};
      }
    }

    this.globals.createLinkForCanonicalURL();
  }

  /**
   * Palauttaa kebabCase-muotoisia tekstirimpsuja selkeiden linkkien
   * generoimiseen (esim. "Humanistinen tiedekunta" ==> "humanistinen-tiedekunta")
   */
  getKebabCase(string: string) {
    return _.kebabCase(string);
  }

}

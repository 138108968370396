/**
 * Tämän avulla saadaan markdowniin halutut tyylimuotoilut
 */
import { Component, Input, OnInit} from '@angular/core';
import { NgxMdService as MarkdownService } from 'ngx-md';
import { Globals } from '../shared/globals';

@Component({
    selector: 'app-markdown',
    template: `
    <markdown [data]="body"></markdown>
    `,
})
export class MarkdownComponent implements OnInit {

  @Input('body') body: string;

  constructor(
    private _markdown: MarkdownService,
    private globals: Globals
  ) {}

  ngOnInit() {

    this._markdown.renderer.blockquote = (quote: string) => {
      return `<blockquote class="king-quote">${quote}</blockquote>`;
    }

    /*this._markdown.renderer.paragraph = (quote: string) => {
      return `${quote}`;
    }*/

    this._markdown.renderer.list = (body: string, ordered: boolean) => {
      return (!ordered) ? `<ul class="browser-default">${body}</ul>` : `<ol class="browser-default">${body}</ol>`;
    }

  }

}

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Globals } from '../shared/globals';
import { NavbarService } from '../my-navbar/navbar.service';
import { OrganisationService } from '../organisation/organisation.service';
import * as _ from 'lodash';
import { Programme } from '../peppi-lum/programme';
import { MarkdownComponent } from '../shared/markdown.component'


@Component({
  selector: 'app-education-description',
  styles: ['.education-content {padding-top: 15px'],
  template: `<div *ngFor="let description of descriptions" >
  <div *ngIf="globals.getLocalizedValue(description?.text)">
  <div *ngIf="description.value.length > 0" class="education-content">
      <div>
          <b>{{globals.getLocalizedValue(description.descriptionField.title)}}</b>
          <p>
            <app-markdown [body]="globals.getLocalizedValue(description?.text)">

            </app-markdown>
          </p>
      </div>
      </div>
  </div>
</div>`
})
export class EducationDescriptionComponent implements OnInit, OnDestroy {

  @Input() id: string;
  descriptions: Object[];
  errorMessage: string;
  educationDescriptionSubscription: any;

  constructor(
    private organisationService: OrganisationService,
    private globals: Globals
  ) {}

  ngOnInit() {
  this.educationDescriptionSubscription = this.organisationService.getEducationDescriptions(this.id).subscribe(
      descriptions => {
        this.descriptions = descriptions;
      },
      error => this.errorMessage = <any>error
    );
  }

  ngOnDestroy() {
    this.educationDescriptionSubscription.unsubscribe();
  }
}

import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute, Params, ParamMap, Router } from '@angular/router';
import { switchMap, flatMap, map, take  } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { Globals } from '../shared/globals';

import { Unit} from './unit';
import { NavbarService } from '../my-navbar/navbar.service';
import { OrganisationService } from '../organisation/organisation.service';
import * as _ from 'lodash';
import { Programme } from '../peppi-lum/programme';
import { Organisation } from '../organisation/organisation'
import { TextService } from '../shared/text.service';
import { LocalizedString } from '../course/localized-string';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { Node } from './node';
import { PlatformLocation } from '@angular/common';
import { MaterializeAction } from 'angular2-materialize';

@Component({
  selector: 'unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.css']
})
export class UnitComponent implements OnInit {

  id: string;
  parent: any;
  isLoaded = false;
  educations: Node[];
  programmes: Programme[];
  learningUnit: Unit;
  educationType: string;
  organisation: any;
  subOrganisations: Organisation[];
  errorMessage: string;
  educationsHeader: LocalizedString;
  period: string;
  actions: EventEmitter<any|MaterializeAction> = new EventEmitter <any|MaterializeAction>();

  private subscription: Subscription = new Subscription();

  frontPageRoute: LocalizedString = environment.frontPageRoute;

  constructor(
    public globals: Globals,
    private route: ActivatedRoute,
    private router: Router,
    private navigationService: NavbarService,
    private organisationService: OrganisationService,
    private textService: TextService,
    private titleService: Title,
    private _platformStrategy: PlatformLocation
  ) { }



  getKebabCase(string: string) {
    return _.kebabCase(string);
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    // tällä saadaan aikaan latauspalkki
    this.subscription.add(this.route.url.subscribe(url => {
      this.isLoaded = false;
    }));

    /**
     * Haut limitetty yhteen observableen sisäkkäisyyksien välttämiseksi
     */

    // parametrien yhdistäminen
    this.subscription.add(combineLatest(this.route.paramMap, this.route.queryParamMap).pipe(
      //take(1),
      map(params => ({
        id: params[0].get('id'), 
        educationType: params[0].get('educationtype'),
        period: params[1].get('period')
      })),
      // koulutustyypin tietojen hakeminen
      flatMap(params => {
          this.id = params.id;
          this.educationType = params.educationType;
          this.period = params.period;
          return this.navigationService.getNavigationItem(this.educationType);             
      }),
      // organisaation tietojen hakeminen
      flatMap((navigationItems) => {     
        this.parent = navigationItems;
        return this.organisationService.getOrganisation(this.id);   
      }),
      // aliorganisaatioiden hakeminen
      flatMap((organisations) => {     
        this.organisation = organisations;
        this.setTitle();
        return this.organisationService.getSubOrganisations(this.id);   
      }),
      // koulutusten hakeminen
      flatMap((subOrganisations) => {   
        this.subOrganisations = subOrganisations;
        this.globals.sortByName(this.subOrganisations, this.globals.lang); 
        return this.organisationService.getEducations(this.id, this.educationType);   
      })
      ).subscribe((educations) => {      
        this.educations = educations;
        this.globals.sortByName2(this.educations, this.globals.lang);
        this.educations.forEach(education => {
          // tutkinto-ohjelmat järjestykseen
          this.globals.sortByName2(education.children, this.globals.lang);
        })
        this.isLoaded = true;
        
        this.setCurrentUrl();         
      }, error => {
          console.log(error);
         // this.router.navigateByUrl('/404');
        }
      ));


    // tällä alustetaan kielivalinta sovellukselle urlista
    this.subscription.add(this.route.params.subscribe(params => {
        if (_.isEqual(params['lang'], 'fi')) {
          this.globals.lang = 'fi'
        }
        if (_.isEqual(params['lang'], 'en')) {
          this.globals.lang = 'en'
        }
        if (_.isEqual(params['lang'], 'sv')) {
          this.globals.lang = 'sv'
        }

    }));

    this.subscription.add(this.textService.getText('educations').subscribe(
      value => this.educationsHeader = value,
      error => this.errorMessage = <any>error
    ));

  } //end of OnInit

  collapse(event, i) {
    if (event.key === "Enter") {
      this.actions.emit({action:"collapsible", params:['open', i]});
    }
  }

  setTitle() {
    if (this.period) {
      this.titleService.setTitle(this.globals.getLocalizedValue(this.organisation.name) + environment.titleExtension + ' ' + this.period);
    } else {
      this.titleService.setTitle(this.globals.getLocalizedValue(this.organisation.name) + environment.titleExtension);
    }
  }
  
  setCurrentUrl() {

    if (this.isLoaded) {
      if (!this.period) {
        this.globals.currentUrl = {
          valueFi: '/fi/' + this.getKebabCase(this.parent.name.valueFi) + '/' + this.getKebabCase(this.organisation.name.valueFi) + '/' + this.educationType + '/' + this.id, 
          valueEn: '/en/' + this.getKebabCase(this.parent.name.valueEn) + '/' + this.getKebabCase(this.organisation.name.valueEn) + '/' + this.educationType + '/' + this.id,
          valueSv: '/sv/' + this.getKebabCase(this.parent.name.valueSv) + '/' + this.getKebabCase(this.organisation.name.valueSv) + '/' + this.educationType + '/' + this.id
        };
      } else {            
        this.globals.currentUrl = {
          valueFi: '/fi/' + this.getKebabCase(this.parent.name.valueFi) + '/' + this.getKebabCase(this.organisation.name.valueFi) + '/' + this.educationType + '/' + this.id + '?period=' + this.period,
          valueEn: '/en/' + this.getKebabCase(this.parent.name.valueEn) + '/' + this.getKebabCase(this.organisation.name.valueEn) + '/' + this.educationType + '/' + this.id + '?period=' + this.period,
          valueSv: '/sv/' + this.getKebabCase(this.parent.name.valueSv) + '/' + this.getKebabCase(this.organisation.name.valueSv) + '/' + this.educationType + '/' + this.id + '?period=' + this.period
        }
      }
    }
    this.globals.createLinkForCanonicalURL();
  } //end of setCurrentUrl()

}

import { forkJoin } from 'rxjs';  // change to new RxJS 6 import syntax
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { Globals } from '../shared/globals';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';

@Injectable({
  providedIn:  'root'
})
export class LottaApiService {

  queryParam: string = ''; // = '?period=2018-2020';
  curriculumPeriod = environment.curriculumPeriod;
  //period: string;

  constructor( 
    public globals: Globals,
    private route: ActivatedRoute,
    private http: HttpClient
    ) { 
      this.globals.schoolYearChanged.subscribe(year => this.onPeriodChange(year)); 
      this.route.queryParams.subscribe(params => {
        params['period'] !== undefined ? this.queryParam = '?period=' + params['period'] : this.queryParam = '';
      });
    }

  /*ngOnInit() {
        this.route.queryParams.subscribe(params => {
      this.queryParam = '?period=' + params['period']
    });
  }*/

  getList<T>(endpoint: string): any {
    return this.http.get(this.buildUrl(endpoint))
     /* .map(this.extractData)
      .catch(this.handleError)*/;
  }

 /* getCourseUnitRealizationList<T>(endpoint: string): Observable<T[]> {
    return this.http.get(`//${environment.backendUrl}/api/course/realization/${endpoint}`)
      .map(this.extractData)
      .catch(this.handleError);
  }*/

  getEducationList<T>(organisation: string, educationType: string) {
  // return  this.http.get(`//${environment.backendUrl}/api/education/${organisation}/${educationType}`);
    return this.http.get(`//${environment.backendUrl}/api/education/${organisation}/${educationType}${this.queryParam}`)
      /*.map(this.extractData)
      .catch(this.handleError)*/;
  }

  getEducationListByType<T>(id: string): any {
    return this.http.get(`//${environment.backendUrl}/api/education/${id}/education-type${this.queryParam}`)
     /* .map(this.extractData)
      .catch(this.handleError)*/;
  }
  getSubList<T>(endpoint: string, id: string): any {
    return this.http.get(this.buildUrlWithId(endpoint, id))
      /*.map(this.extractData)
      .catch(this.handleError)*/;
  }

  getOne<T>(endpoint: string, id: string): any {
    
    return this.http.get(this.buildUrlWithId(endpoint, id))
     /* .map(this.extractData)
      .catch(this.handleError)*/;
  }

  search<T>(endpoint: string): any {
    return this.http.get(this.buildUrl(endpoint))
     /* .map(this.extractData)
      .catch(this.handleError)*/;
  }

  /*getText(key: string): Promise<any> {
     return this.http.get(`//${environment.backendUrl}/api/course/text/${key}`)
      .map(this.extractData).toPromise()
      .catch(this.handleError);
  }*/

  private buildUrl(endpoint: string): string {
    return `//${environment.backendUrl}/api/${endpoint}${this.queryParam}`;
  }

  private buildUrlWithId(endpoint: string, id: string): string {
    return `//${environment.backendUrl}/api/${endpoint}/${id}${this.queryParam}`;
  }

  private onPeriodChange(year: number): void {
    this.queryParam = '?period=' + this.globals.selectedCurriculumPeriod;
    //this.globals.selectedSchoolYear.toString() + '-' + (this.globals.selectedSchoolYear+this.curriculumPeriod).toString();
    //console.log('kutsun periodi: ' + this.queryParam);
   }

 /* private extractData(res: Response) {
    return res.json() || {};
  }

  private handleError(error: Response | any) {*/
    // In a real world app, you might use a remote logging infrastructure
   // let errMsg: string;
   // if (error instanceof Response) {
   //   const body = error.json() || '';
     // const err = body.erro || JSON.stringify(body);
     // errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
   // } else {
   /*   errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);*/
  //  return throwError(error);
 // }

  /*private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }*/

}

import { Component, OnInit, OnDestroy,  } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { flatMap, map } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { Globals } from '../shared/globals';
import * as _ from 'lodash';
import { CourseUnitRealization } from '../course/course-unit-realization';
import { StudyModuleService } from './study-module.service';
import { TextService } from '../shared/text.service';
import { LocalizedString } from '../course/localized-string';
import { StudyModuleType } from './study-module-type';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

/**
 * Tämän on tarkoitus hanskata opintokokonaisuuksien (study module) lisäksi
 * myös erilliset kokonaisuudet (offering) sekä ryhmittelykokonaisuudet (gategory)
 */

@Component({
  // selector: 'study-module',
  templateUrl: './study-module.component.html',
  styleUrls: ['./study-module.component.css']
})
export class StudyModuleComponent implements OnInit, OnDestroy {

  studyModule: any;
  studyModuleType: StudyModuleType;
  realizations: CourseUnitRealization[];
  structureHeader: LocalizedString;
  creditPoints: LocalizedString;
  offeringNotPublished: LocalizedString;
  errorMessage: string;
  isLoaded = false;

  id: string;
  period: string;
  private subscription: Subscription = new Subscription();

  constructor(
     private studyModuleService: StudyModuleService,
     private globals: Globals,
     private route: ActivatedRoute,
     private router: Router,
     private textService: TextService,
     private titleService: Title
    ) {}

    ngOnInit(): void {

      this.subscription.add(this.textService.getText('credit.points').subscribe(
        value => this.creditPoints = value,
        error => this.errorMessage = <any>error
      ));

      this.subscription.add(this.textService.getText('study.module.structure').subscribe(
        value => this.structureHeader = value,
        error => this.errorMessage = <any>error
      ));

      this.subscription.add(this.textService.getText('offering.not.published').subscribe(
        value => this.offeringNotPublished = value,
        error => this.errorMessage = <any>error
      ));

      this.subscription.add(combineLatest(this.route.paramMap, this.route.queryParamMap).pipe(
        map(params => ({
          id: params[0].get('id'), 
          period: params[1].get('period')
        })),
        // haetaan jakso
        flatMap(params => {
          this.id = params.id;
          this.period = params.period;
          return this.studyModuleService.getStudyModule(this.id);
        }),
        flatMap(studyModule => {
          this.studyModule = studyModule;
          return this.studyModuleService.getType(this.id);
        })
        ).subscribe(
          studyModuleType => {
            this.studyModuleType = studyModuleType[0];
            this.setCurrentUrl();
            this.setTitle();
            this.isLoaded = true;
          }, error => {
            console.log(error);
            this.router.navigateByUrl('/404');
          }
          
        ));
     
      // tällä alustetaan kielivalinta sovellukselle urlista
      this.subscription.add(this.route.params.subscribe(params => {
        if (_.isEqual(params['lang'], 'fi')) {
          this.globals.lang = 'fi'
        }
        if (_.isEqual(params['lang'], 'en')) {
          this.globals.lang = 'en'
        }
        if (_.isEqual(params['lang'], 'sv')) {
          this.globals.lang = 'sv'
        }
        }
      ));

    }

    ngOnDestroy(): void {
      this.subscription.unsubscribe();
    }

    isEqual(param: string, param2: string): boolean {
      return (_.isEqual(param, param2));
    }

    setTitle() {
      if (this.period) {
        this.titleService.setTitle(this.globals.getLocalizedValue(this.studyModule.name) + environment.titleExtension + ' ' + this.period);
      } else {
        this.titleService.setTitle(this.globals.getLocalizedValue(this.studyModule.name) + environment.titleExtension);
      }
    }
    
    setCurrentUrl() {
      // asetetaan url
      if (!this.period) {
        this.globals.currentUrl = {
          valueFi: '/fi/' + this.globals.getLocalizedStudyModuleType('fi', this.studyModuleType.type) + '/' + this.studyModule.code + '/' + this.id,
          valueEn: '/en/' + this.globals.getLocalizedStudyModuleType('en', this.studyModuleType.type) + '/' + this.studyModule.code + '/' + this.id,
          valueSv: '/sv/' + this.globals.getLocalizedStudyModuleType('sv', this.studyModuleType.type) + '/' + this.studyModule.code + '/' + this.id
        }
      } else {
        this.globals.currentUrl = {
          valueFi: '/fi/' + this.globals.getLocalizedStudyModuleType('fi', this.studyModuleType.type) + '/' + this.studyModule.code + '/' + this.id + '?period=' + this.period,
          valueEn: '/en/' + this.globals.getLocalizedStudyModuleType('en', this.studyModuleType.type) + '/' + this.studyModule.code + '/' + this.id + '?period=' + this.period,
          valueSv: '/sv/' + this.globals.getLocalizedStudyModuleType('sv', this.studyModuleType.type) + '/' + this.studyModule.code + '/' + this.id + '?period=' + this.period
        }
      } 
      this.globals.createLinkForCanonicalURL();
    }

}

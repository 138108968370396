import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterializeModule } from 'angular2-materialize';
import { RouterModule, Routes } from '@angular/router';
import { StudyModuleComponent } from './study-module.component';
import { StudyModuleService } from './study-module.service';
import { SharedModule } from '../shared/shared.module';
import { StructureUnitComponent } from './structure-unit.component';

const routes: Routes = [
   { path: ':lang/study-module/:name/:id', component: StudyModuleComponent },
   { path: ':lang/opintokokonaisuus/:name/:id', component: StudyModuleComponent },
   { path: ':lang/studiehelhet/:name/:id', component: StudyModuleComponent },
   { path: ':lang/offering/:name/:id', component: StudyModuleComponent },
   { path: ':lang/erillinen-opintokokonaisuus/:name/:id', component: StudyModuleComponent },
   { path: ':lang/separat-studiehelhet/:name/:id', component: StudyModuleComponent },
   { path: ':lang/category/:name/:id', component: StudyModuleComponent },
   { path: ':lang/ryhmittely/:name/:id', component: StudyModuleComponent },
   { path: ':lang/gruppering/:name/:id', component: StudyModuleComponent }

];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        SharedModule,
        MaterializeModule
    ],
    exports: [
        RouterModule
    ],
    providers: [StudyModuleService],
    declarations: [StudyModuleComponent, StructureUnitComponent]
})
export class StudyModuleRoutingModule { }

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';
import { Globals } from '../shared/globals';
import * as _ from 'lodash';
import { CourseUnit } from '../course/course-unit';
import { CourseUnitRealization } from '../course/course-unit-realization';
import { TextService } from '../shared/text.service';
import { LocalizedString } from '../course/localized-string';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { SearchService } from './search.service';
import { SearchResult } from './search-result';



@Component({
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit, OnDestroy {

  showInitialized = false;
  courseUnit: CourseUnit;
  courseUnitRealization: CourseUnitRealization[];
  isLoaded = true;
  result: SearchResult;
  title: LocalizedString;
  header: LocalizedString;
  course: LocalizedString;
  studyModule: LocalizedString;
  programme: LocalizedString;
  code: LocalizedString;
  name: LocalizedString
  notFound: LocalizedString;
  errorMessage: string;
  studyType = 'COURSE_UNIT';
  searchParam = '';
  period: string;
  private subscription: Subscription = new Subscription();

  constructor(
    private globals: Globals,
    private route: ActivatedRoute,
    private textService: TextService,
    private titleService: Title,
    private searchService: SearchService
   ) { }

  ngOnInit(): void {

    /*this.subscription.add(this.textService.getText('search.result.title').subscribe(
      value => {
        this.title = value;
        console.log(this.title);
        //this.titleService.setTitle(this.globals.getLocalizedValue(value) + environment.titleExtension);
      },
      error => this.errorMessage = <any>error
    ));*/

    this.subscription.add(this.textService.getText('search.result.header').subscribe(
      value => this.header = value,
      error => this.errorMessage = <any>error
    ));

    this.subscription.add(this.textService.getText('search.result.course').subscribe(
      value => this.course = value,
      error => this.errorMessage = <any>error
    ));

    this.subscription.add(this.textService.getText('search.result.studymodule').subscribe(
      value => this.studyModule = value,
      error => this.errorMessage = <any>error
    ));

    this.subscription.add(this.textService.getText('search.result.programme').subscribe(
      value => this.programme = value,
      error => this.errorMessage = <any>error
    ));

    this.subscription.add(this.textService.getText('search.result.code').subscribe(
      value => this.code = value,
      error => this.errorMessage = <any>error
    ));

    this.subscription.add(this.textService.getText('search.result.name').subscribe(
      value => this.name = value,
      error => this.errorMessage = <any>error
    ));

    this.subscription.add(this.textService.getText('search.result.not.found').subscribe(
      value => this.notFound = value,
      error => this.errorMessage = <any>error
    ));

    // tällä alustetaan kielivalinta sovellukselle urlista
    this.subscription.add(this.route.params.subscribe(params => {
      if (_.isEqual(params['lang'], 'fi')) {
        this.globals.lang = 'fi'
      }
      if (_.isEqual(params['lang'], 'en')) {
        this.globals.lang = 'en'
      }
      if (_.isEqual(params['lang'], 'sv')) {
        this.globals.lang = 'sv'
      }
      /*if (!_.isEmpty(params['param'])) {
        this.searchParam = params['param'];
        this.search();
      }*/
    }));

    this.subscription.add(combineLatest(this.route.paramMap, this.route.queryParamMap).pipe(
      map(params => ({
        param: params[0].get('param'), 
        period: params[1].get('period')
      })), 
      flatMap(params => {
        this.searchParam = params.param;
        this.period = params.period;
        return this.textService.getText('search.result.title');
      })
    ).subscribe(title => {
      this.title = title;
      this.setTitle();
      this.setCurrentUrl();
      this.search();
    }, error => this.errorMessage = <any>error));

    //this.setCurrentUrl();

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setTitle() {
    if(this.period) {
      this.titleService.setTitle(this.globals.getLocalizedValue(this.title) + environment.titleExtension) + '?period=' + this.period;
    } else {
      this.titleService.setTitle(this.globals.getLocalizedValue(this.title) + environment.titleExtension);
    }
  }

  setCurrentUrl() {
    // asetetaan url
      if (!this.period) {
        this.globals.currentUrl = {
          valueFi: '/fi/' +  'haku/' + this.searchParam,
          valueEn: '/en/' + 'search/' + this.searchParam,
          valueSv: '/sv/' + 'search/' + this.searchParam
        }
      } else {
        this.globals.currentUrl = {
          valueFi: '/fi/' +  'haku/' + this.searchParam + '?period=' + this.period,
          valueEn: '/en/' + 'search/' + this.searchParam + '?period=' + this.period,
          valueSv: '/sv/' + 'search/' + this.searchParam + '?period=' + this.period
        }
      }      
      this.globals.createLinkForCanonicalURL(); 
  }


  setType(type: string) {
    this.studyType = type;
    this.search();
  }

  search() {
    if (this.searchParam.length > 3) {
      this.isLoaded = false;
      this.subscription.add(this.searchService.search(this.searchParam, this.studyType).subscribe(
        value => this.result = value,
        error => this.errorMessage = <any>error,
        () => this.isLoaded = true
      ));
    }
  }

}

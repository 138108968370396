import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProgrammeComponent } from './programme.component';

const routes: Routes = [
   //{ path: ':lang/:koulutustyyppi/:organisaatioyksikko/:laitos/tutkinto-ohjelma/:id', component: ProgrammeComponent },
   //{ path: ':lang/:koulutustyyppi/:organisaatioyksikko/tutkinto-ohjelma/:id', component: ProgrammeComponent },
   { path: ':lang/ohjelma/:id', component: ProgrammeComponent },
   { path: ':lang/programme/:id', component: ProgrammeComponent },
   { path: ':lang/program/:id', component: ProgrammeComponent }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class ProgrammeRoutingModule { }

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SearchComponent } from './search.component';

const routes: Routes = [
  { path: ':lang/haku', component: SearchComponent },
  { path: ':lang/haku/:param', component: SearchComponent },
  { path: ':lang/search', component: SearchComponent },
  { path: ':lang/search/:param', component: SearchComponent }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class SearchRoutingModule { }

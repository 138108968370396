import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Unit} from './unit';
import { LottaApiService } from '../lotta-api/lotta-api.service';

@Injectable()
export class UnitService {

  endpoint: string = 'unit/refs';

  constructor(private api: LottaApiService) { }

  getUnit(id: string): Observable<Unit> {
        return this.api.getOne<Unit>(this.endpoint, id);
  }

  getUnits(id: string): Observable<Unit[]> {
    return this.api.getSubList<Unit>(this.endpoint, id);
  }

  getEducations(id: string): Observable<Unit[]> {
    return this.api.getSubList<Unit>(this.endpoint, id);
  }

  getSubUnits(id: string): Observable<Unit[]> {
    return this.api.getSubList<Unit>(this.endpoint + '/units', id);
  }

 // getProgrammes(id: string): Observable
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { UnitComponent } from './unit.component';
import { UnitListComponent } from './unit-list/unit-list.component';
import { UnitService } from './unit.service';
import { SharedModule } from '../shared/shared.module';

const routes: Routes = [
  //{ path: ':lang/:koulutustyyppi/:id/o', component: OrganisationListComponent }
  { path: ':lang/:koulutustyyppi/:id', pathMatch: 'full', component: UnitListComponent },
  { path: ':lang/:koulutustyyppi/:organisaatioyksikko/:educationtype/:id', pathMatch: 'full', component: UnitComponent }, // menee tänne vaikka ei saisi
 // { path: ':lang/:organisaatioyksikko/:educationtype/:id', component: UnitComponent }, // menee tänne vaikka ei saisi
 // { path: ':lang/:organisaatioyksikko/:anything/:educationtype/:id', component: UnitComponent },
 // { path: ':lang/:koulutustyyppi/:organisaatioyksikko/:oppiaineyksikko/:educationtype/:id', component: UnitComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
      //  CommonModule
         SharedModule
    ],
    exports: [
        RouterModule
    ],
    providers: [
     // UnitService
    ]
})
export class UnitRoutingModule { }

import { Component } from '@angular/core';

@Component({
  selector: 'my-spinner',
  template: `
    <!--<i class="fa fa-spinner fa-pulse fa-4x" aria-hidden="true"></i>-->
    <div class="preloader-wrapper active hide-on-med-and-down">
    <div class="spinner-layer spinner-blue-only">
      <div class="circle-clipper left">
        <div class="circle"></div>
      </div><div class="gap-patch">
        <div class="circle"></div>
      </div><div class="circle-clipper right">
        <div class="circle"></div>
      </div>
    </div>
  </div>

  <div class="preloader-wrapper small active hide-on-large-only">
    <div class="spinner-layer spinner-blue-only">
      <div class="circle-clipper left">
        <div class="circle"></div>
      </div><div class="gap-patch">
        <div class="circle"></div>
      </div><div class="circle-clipper right">
        <div class="circle"></div>
      </div>
    </div>
  </div>
  `
})
export class MySpinnerComponent {
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterializeModule } from 'angular2-materialize';
import { NgxMdModule as MarkdownModule } from 'ngx-md';

import { SharedModule } from '../shared/shared.module';

import { OrganisationRoutingModule } from './organisation-routing.module';
import { OrganisationService } from './organisation.service';
import { OrganisationListComponent } from './organisation-list/organisation-list.component';
// import { OrganisationPageComponent } from './organisation-page.component';


@NgModule({
  imports: [
    CommonModule,
    MaterializeModule,
    MarkdownModule.forRoot(),
    SharedModule,
    OrganisationRoutingModule
  ],
  declarations: [
  //  OrganisationPageComponent,
    OrganisationListComponent
  ],
  providers: [OrganisationService],
  exports: [
    OrganisationListComponent
  ]
})
export class OrganisationModule { }

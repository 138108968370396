import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterializeModule } from 'angular2-materialize';
import { SearchRoutingModule } from './search-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CourseComponent } from '../course/course.component';
import { NgxMdModule as MarkdownModule } from 'ngx-md';
import { SearchComponent } from './search.component';
import { SearchService } from './search.service';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
  FormsModule,
  CommonModule,
  MaterializeModule,
  SharedModule,
  MarkdownModule.forRoot(),
  SearchRoutingModule
  ],
  declarations: [SearchComponent],
  providers: [SearchService]
})
export class SearchModule { }

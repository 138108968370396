import { Component, OnInit, EventEmitter, Renderer, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap, NavigationEnd } from '@angular/router';

import { environment } from '../../environments/environment';
import { NavbarService } from './navbar.service';
import * as _ from 'lodash';
import { Globals } from '../shared/globals';
import { MaterializeAction } from 'angular2-materialize';
import { TextService } from '../shared/text.service';


@Component({
  selector: 'my-navbar',
  templateUrl: './my-navbar.component.html',
  styleUrls: ['./my-navbar.component.css']
})
export class MyNavbarComponent implements OnInit, OnDestroy {

  actions: EventEmitter<string|MaterializeAction> = new EventEmitter <string|MaterializeAction>();
  navigationItems: any;
  errorMessage: string;
  header: any;
  logoLocation = environment.logo;
  showSearchField = environment.showSearchField;
  searchPeriod = environment.searchPeriod;
  showTermSelection = environment.showTermSelection;
  currentPeriodStartYear = environment.currentPeriodStartYear;
  searchParams = '';
  placeholder: any;
  @ViewChild('searchField') searchField: ElementRef;
  firstSchoolYear = environment.firstSchoolYear;
  curriculumPeriod = environment.curriculumPeriod; //pituus
  //currentPeriodStartYear: number;
  selectedSchoolYear: number;
  period: string;

  navigationSubscription: any;
  curriculumPeriodSubscription: any;
  // selectedYearSubscription: any;

  constructor(
    public globals: Globals,
    private route: ActivatedRoute,
    private navbarService: NavbarService,
    private router: Router,
    private renderer: Renderer,
    private textService: TextService
  ) { 
    this.curriculumPeriodSubscription = this.globals.curriculumPeriodChanged.subscribe( period => this.onCurriculumPeriodChange(period));
  }
  
  private onCurriculumPeriodChange(period: string): void {
    this.selectedSchoolYear = Number(period.split('-')[0]);
    this.globals.selectedCurriculumPeriod = period;
    this.period = period;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl(this.globals.getLocalizedValue(environment.frontPageRoute).split('?')[0] + '?period=' + period); 
    //window.location.href = this.globals.getLocalizedValue(environment.frontPageRoute).split('?')[0] + '?period=' + period;
  }

  ngOnInit() {

   this.reset();

    this.getNavigationItems();
    this.actions.emit({action: 'sideNav', params: ['show']});

    this.textService.getText('header').subscribe(
      value => this.header = value,
      error => this.errorMessage = <any>error
    );

    this.textService.getText('search.input.placeholder').subscribe(
      value => this.placeholder = value,
      error => this.errorMessage = <any>error
    );

    this.route.queryParams.subscribe(params => {
      this.period = params['period'];  
      
      if(this.period) {
        this.selectedSchoolYear = Number(this.period.split('-')[0]);
        this.globals.selectedCurriculumPeriod = this.period;

      }
    });
    
  }

  ngOnDestroy() {
    this.navigationSubscription.unsubscribe();
    this.curriculumPeriodSubscription.unsubscribe();
  }

  reset(): void {
    const now = new Date();
    //let currentSchoolYear = now.getMonth() >= 7 ? now.getFullYear() : now.getFullYear() - 1;
    //jos aloitusvuosi ja opintokauden pituus parillisia, täytyy nykyisen kauden alunkin olla
    /*if (this.firstSchoolYear % 2 === 0 && this.currentSchoolYear % 2 === 0) {
      this.currentPeriodStartYear = this.currentSchoolYear;
    } else {
      this.currentPeriodStartYear = this.currentSchoolYear-1;
    }*/
    
    if(!this.period) {
      this.selectedSchoolYear = this.currentPeriodStartYear;
      this.period = this.selectedSchoolYear.toString() + '-' + (this.selectedSchoolYear + this.curriculumPeriod).toString();
    }

  }

  increase(): void {
       this.globals.setSelectedSchoolYear(this.selectedSchoolYear + this.curriculumPeriod);
       this.globals.setSelectedCurriculumPeriod((this.selectedSchoolYear + this.curriculumPeriod) + '-' + (this.selectedSchoolYear + (2*this.curriculumPeriod)));
  }

  increase2(event): void {
    if (event.key === "Enter") {
      this.increase();
    }
  }

  decrease(): void {
      this.globals.setSelectedSchoolYear(this.selectedSchoolYear - this.curriculumPeriod);
      this.globals.setSelectedCurriculumPeriod(this.selectedSchoolYear-this.curriculumPeriod + '-' + this.selectedSchoolYear);
  }

  decrease2(event): void {
    if (event.key === "Enter") {
      this.decrease();
    }
  }

  setUrl(): void {
  
  this.globals.createLinkForCanonicalURL();

  }

  getNavigationItems() {
    this.navigationSubscription = this.navbarService.getNavigationItems().subscribe(
      navigationItems => this.navigationItems = navigationItems,
      error => this.errorMessage = <any>error
    );

  }

  search() {

    if (this.searchParams.length > 3) {
      const landingUrl = '/' + this.globals.lang + '/haku/' + this.searchParams + (this.period ?  '?period=' + this.period : '');
      this.router.navigateByUrl(landingUrl);
      this.searchParams = '';
      this.renderer.invokeElementMethod(
        this.searchField.nativeElement, 'blur', []);
    }
  }

  closeNav() {
    this.actions.emit({action: 'sideNav', params: ['hide']});
  }

  getKebabCase(string: string) {
    return _.kebabCase(string);
  }

  changeLang(lang: string) {
    this.globals.setLang(lang);
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl(this.globals.getCurrentUrl(lang)); 
  }
  
  changeLang2(event, lang: string) {
    if (event.key === "Enter") {
      this.changeLang(lang);
    }
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LottaApiService } from '../lotta-api/lotta-api.service';
import { SearchResult } from './search-result';

@Injectable()
export class SearchService {
  constructor(private api: LottaApiService) { }

  search(param: string, type: string): Observable<SearchResult> {
    return this.api.search<SearchResult>(`lu/units/${param}/${type}`);
  }
}

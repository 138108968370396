import { Component, OnInit, AfterContentChecked} from '@angular/core';
import { environment } from '../environments/environment';
import { TextService } from './shared/text.service';
import { Globals } from './shared/globals';
import { LocalizedString } from './course/localized-string';
import { ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { withLatestFrom, filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterContentChecked {
  header: LocalizedString;
  errorMessage: string;
  backendUrl = environment.backendUrl;
  background = environment.background;
  headerTransparency = environment.headerTransparency;
  textServiceSubscription: any;

  constructor(
    public globals: Globals,
    private textService: TextService,
    private cdref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router
  ) { 
    const navEnd$ = this.router.events.pipe(filter(e => e instanceof NavigationEnd));
   navEnd$.pipe(withLatestFrom(this.route.queryParams)).subscribe(
     ([navEnd, queryParams]) => {
       if (queryParams.period) {
         // do the thing
       } else {
         // navigate
         console.log('query param missing')
         this.router.navigate(['/']);
       }
     }
   )
  }

  ngOnInit(): void {
    this.textServiceSubscription = this.textService.getText('header').subscribe(
      value => this.header = value,
      error => this.errorMessage = <any>error
    );
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMdModule as MarkdownModule } from 'ngx-md';
import { MySpinnerComponent } from './my-spinner.component';
import { MarkdownComponent } from './markdown.component';
import { Utils } from './utils';
import { TextService } from './text.service';

@NgModule({
  imports: [
    MarkdownModule.forRoot(),
    CommonModule
  ],
  declarations: [
    MySpinnerComponent,
    MarkdownComponent
  ],
  exports: [
    MySpinnerComponent,
    MarkdownComponent
  ],
  providers: [
    Utils,
    TextService
  ]
})
export class SharedModule { }

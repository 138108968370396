import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';

import { TextService } from '../shared/text.service';
import { ProgrammeService } from '../programme/programme.service';
import { LocalizedString } from './localized-string';
import { Globals } from '../shared/globals';
import { flatMap, map } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'app-programmes',
 // styleUrls: ['./programme.component.css'],
  styles: ['li {list-style-type: square !important; list-style-position: inside; padding-left: 28px'],
  template: `
  <div *ngIf="programmes && programmes.length > 0" class="col s12 m6">
    <h5 *ngIf="this.programmesHeader">{{globals.getLocalizedValue(this.programmesHeader)}}</h5>
    <ul>
        <li *ngFor="let programme of programmes">
          <a *ngIf="!period" routerLink="/{{globals.lang}}/{{globals.getDegreeProgramme()}}/{{programme?.learningUnitId}}">{{globals.getLocalizedValueIfAny(programme.name)}}</a>
          <a *ngIf="period" routerLink="/{{globals.lang}}/{{globals.getDegreeProgramme()}}/{{programme?.learningUnitId}}" [queryParams]="{period: period}">{{globals.getLocalizedValueIfAny(programme.name)}}</a>
        </li>
    </ul>
  </div>
  `
})
export class ProgrammeComponent implements OnInit, OnDestroy {

  @Input() id: string;
  errorMessage: string;
  programmesHeader: LocalizedString;
  programmes: Object[];
  // @Input('education-type') educationType: string;
  private subscription: Subscription = new Subscription();
  period: string;
  isLoaded = false;

  constructor(
    private globals: Globals,
    private textService: TextService,
    private programmeService: ProgrammeService,
    private route: ActivatedRoute,
    private router: Router
  ) {

  }


  ngOnInit(): void {
    // näiden tulisi olla OnInitissä, mutta aiheuttavat lokalisoinnin takia siellä ongelmia
    this.subscription.add(this.textService.getText('course.programmes').subscribe(
      value => this.programmesHeader = value,
      error => this.errorMessage = <any>error
    ));
    this.subscription.add(this.programmeService.getRelatedProgrammes(this.id).subscribe(
      value => this.programmes = value,
      error => this.errorMessage = <any>error
    ));

    this.subscription.add(this.route.queryParamMap.subscribe(
      value => this.period = value.get('period'),
      error => this.errorMessage = <any>error
    ));

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

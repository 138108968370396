export const environment = {
    production: true,
    backendUrl: 'opas.peppi.uef.fi',
    primaryLang: 'fi',
    secondaryLang: 'en',
    tertiaryLang: '',
    redirectRoute: '/fi/index?period=2024-2025',
    frontPageRoute: {valueFi: '/fi/index?period=2024-2025', valueEn: '/en/index?period=2024-2025', valueSv: ''},
    // tähän organisaatiopuun juuren id
    frontPageOrganisationIndex: '13899', //'13844',
    logo: 'assets/uef/logo',
    favicon: 'assets/uef/favicon.ico',
    background: 'assets/uef/tausta.png',
    headerTransparency: true,
    // tähän voi antaa osoitteen, josta injektoidaan jokin javascript-tiedosto <HEAD>-tagien sisään
    analyticsIcluded: false,
    analytics: '',
    // sivujen title-tiedon perään lisättävä lisäke
    titleExtension: ' | Opinto-opas',
    showSearchField: true,
    showTermSelection: true,
    firstSchoolYear: 2021,
    currentPeriodStartYear: 2024,
    searchPeriod: 2024,
    curriculumPeriod: 1,
    showTags: false
  };

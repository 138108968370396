// https://angular.io/docs/ts/latest/guide/router.html#!#routing-module
import { NgModule, Inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppConfig } from './app.config';
import { environment } from '../environments/environment';

export const ROUTES: Routes = [
  // If you need more routes, please consider creating new modules containing them.
  { path: '', redirectTo: environment.redirectRoute, pathMatch: 'full' }, 
  { path: '**', redirectTo: '/404' } // NotFoundComponent
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

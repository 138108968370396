import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './not-found.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { MaterializeModule } from 'angular2-materialize';
 
const routes: Routes = [ 
    { path: ':lang/404', component: NotFoundComponent }
    
];
 
@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    SharedModule,
    MaterializeModule
  ],
  exports: [
      RouterModule
    ],
  declarations: [
    NotFoundComponent
  ]
})
export class NotFoundRoutingModule {
}
 
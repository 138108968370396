import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LottaApiService } from '../lotta-api/lotta-api.service';
import { Organisation } from './organisation';
import { OrganisationUnit } from './organisation-unit';
import { Globals } from '../shared/globals';
import { environment } from '../../environments/environment';
import { Node } from '../unit/node';
//import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';

@Injectable()
export class OrganisationService {

  endpoint: string = 'organisation';
  period: string;
  curriculumPeriod = environment.curriculumPeriod; //pituus
  queryParam: string = '?period=2018-2020';

  constructor(
    public globals: Globals,
    private api: LottaApiService
    ){ 
      this.globals.schoolYearChanged.subscribe(year => this.onPeriodChange(year)); 
    }

  /*ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.period = params['period'];  
    });
  }*/
  getOrganisationTree(): Observable<Organisation[]> {
    return this.api.getList<Organisation>('organisation/tree');
  }

  /*getEducations(id: string): Observable<Organisation[]> {
    return this.api.getSubList<Organisation>(this.endpoint, id);
  }*/

  getOrganisation(id: string): Observable<Organisation> {
    return this.api.getOne<Organisation>(this.endpoint, id);
  }


  getOrganisations(): Observable<Organisation[]> {
    return this.api.getList<Organisation>(this.endpoint);
  }

  getSubOrganisations(id: string): Observable<Organisation[]> {
    return this.api.getSubList<Organisation>(this.endpoint + '/units', id);
  }
  getEducationList<T>(organisation: string, educationType: string): Observable<T[]> {
    return this.api.getList(`/education/${organisation}/${educationType}`)
  }

  getEducationListByType<T>(id: string): Observable<T[]> {
    return this.api.getList(`/education/${id}/education-type`);
  }

  getEducations(organisation: string, educationType: string): Observable<any> {
    return this.api.getEducationList(organisation, educationType);
  }

  getEducationListBySchoolYear<T>(id: string): Observable<T[]> {
    return this.api.getList(`education/${id}`);
  }
  getEducationsByType(id: string): Observable<Node[]> {
    return this.api.getEducationListByType(id);
  }
  getEducationDescriptions(id: string): Observable<Object[]> {
    return this.api.getList<Object>('education/description/' + id);
  }

  getEducationTypesByOrganisation(id: string): Observable<any> {
    return this.api.getList('education-type/' + id);
  }

  private onPeriodChange(year: number): void {
    this.queryParam = '?period=' + this.globals.selectedSchoolYear.toString() + '-' + (this.globals.selectedSchoolYear+this.curriculumPeriod).toString();
    //console.log(this.period);
   }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalizedString } from '../course/localized-string'
import { LottaApiService } from '../lotta-api/lotta-api.service';


@Injectable()
export class TextService {

  endpoint = 'text';

  constructor(private api: LottaApiService) { }



  getText(key: string): Observable<LocalizedString> {
    return this.api.getOne<LocalizedString>(this.endpoint, key);
  }



}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OrganisationListComponent } from './organisation-list/organisation-list.component';


const organisationRoutes: Routes = [
    { path: ':lang/:koulutustyyppi/:id/o', pathMatch: 'full', component: OrganisationListComponent, runGuardsAndResolvers: 'always' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(organisationRoutes, {onSameUrlNavigation: 'reload'})
    ],
    exports: [
        RouterModule
    ]
})
export class OrganisationRoutingModule { }

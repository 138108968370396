import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LottaApiService } from '../lotta-api/lotta-api.service';
import { CourseUnit } from './course-unit';
import { CourseUnitRealization } from './course-unit-realization';
import { LocalizedString}  from './localized-string';

@Injectable()
export class CourseService {

  constructor(private api: LottaApiService) { }

  getCourseUnit(unitId: string): Observable<CourseUnit> {
    return this.api.getOne('course', unitId);
  }

  getCourseUnitRealization(unitId: string): Observable<CourseUnitRealization[]> {
    // return this.api.getSubList('course/realization', unitId);
    return this.api.getSubList('realizations/course', unitId);
  }

  getPastCourseUnitRealization(unitId: string): Observable<CourseUnitRealization[]> {
    return this.api.getSubList('realizations/past/course', unitId);
  }

  getText(key: string): Observable<LocalizedString> {
    return this.api.getOne('course/text', key);
 }

}

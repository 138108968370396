import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterializeModule } from 'angular2-materialize';
import { NgxMdModule as MarkdownModule } from 'ngx-md';

import { SharedModule } from '../shared/shared.module';

import { EducationTypeRoutingModule } from './education-type-routing.module';
import { EducationTypeComponent } from './education-type.component';
import { EducationComponent } from './education.component';
import { EducationDescriptionComponent } from './education-description.component';



@NgModule({
  imports: [
    CommonModule,
    MaterializeModule,
    MarkdownModule.forRoot(),
    SharedModule,
    EducationTypeRoutingModule
  ],
  declarations: [
    EducationTypeComponent,
    EducationComponent,
    EducationDescriptionComponent
  ],
  providers: [],
  exports: [
    EducationTypeComponent,
    EducationComponent,
    EducationDescriptionComponent
  ]
})
export class EducationTypeModule { }

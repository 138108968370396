import { Component, Input, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { flatMap, map } from 'rxjs/operators';
import { Subscription, combineLatest } from 'rxjs';
import { Globals } from '../../shared/globals';
import { Unit} from './../unit';
import { NavbarService } from '../../my-navbar/navbar.service';
import { OrganisationService } from '../../organisation/organisation.service';
import * as _ from 'lodash';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { Node } from '../node';
import { LocalizedString } from 'app/course/localized-string';
import { MaterializeAction } from 'angular2-materialize';

@Component({
  selector: 'unit-list',
  templateUrl: './unit-list.component.html',
  styleUrls: ['./unit-list.component.css']
})
export class UnitListComponent implements OnInit, OnDestroy {

  @Input('unit') id: string;
  units: Unit;
  isLoaded = false;
  educations: Node[];
  educationType: any;
  period: string;
  actions: EventEmitter<any|MaterializeAction> = new EventEmitter <any|MaterializeAction>();


  private subscription: Subscription = new Subscription();
  frontPageRoute: LocalizedString = environment.frontPageRoute;

  constructor(
    private globals: Globals,
    private route: ActivatedRoute,
    private router: Router,
    private navigationService: NavbarService,
    private organisationService: OrganisationService,
    private titleService: Title
  ) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getKebabCase(string: string) {
    return _.kebabCase(string);
  }

   ngOnInit(): void {
       
    // tällä saadaan aikaan latauspalkki
    this.subscription.add(this.route.url.subscribe(url => {
      this.isLoaded = false;
    }));

    this.subscription.add(combineLatest(this.route.paramMap, this.route.queryParamMap).pipe(
      map(params => ({
        id: params[0].get('id'), 
        period: params[1].get('period')
      })),
      // koulutustyypin tietojen hakeminen
      flatMap(params => {
        //this.isLoaded = false;
        this.id = params.id;
        this.period = params.period;
        return this.organisationService.getEducationsByType(this.id);
      }),
      flatMap(educations => {
        this.educations = educations;
        this.globals.sortByName2(educations, this.globals.lang);
        this.educations.forEach(education => {
          this.globals.sortByName2(education.children, this.globals.lang);
        })
        return this.navigationService.getNavigationItem(this.id);
      })).subscribe(navigationItem => {
        this.educationType = navigationItem;
        this.setTitle();
        this.setCurrentUrl();
        this.isLoaded = true;
      }, error => {
        console.log(error);
        //this.router.navigateByUrl('/404');
      }
      ));

      // tällä alustetaan kielivalinta sovellukselle urlista
      this.subscription.add(this.route.params.subscribe(params => {
        if (_.isEqual(params['lang'], 'fi')) {
          this.globals.lang = 'fi'
        }
        if (_.isEqual(params['lang'], 'en')) {
          this.globals.lang = 'en'
        }
        if (_.isEqual(params['lang'], 'sv')) {
          this.globals.lang = 'sv'
        }
      }));

    }

    collapse(event, i) {
      if (event.key === "Enter") {
        this.actions.emit({action:"collapsible", params:['open', i]});
      }
    }

    setTitle() {
      if(this.period != undefined) {
        this.titleService.setTitle(this.globals.getLocalizedValue(this.educationType.name) + environment.titleExtension + ' ' + this.period);
      } else {
        this.titleService.setTitle(this.globals.getLocalizedValue(this.educationType.name) + environment.titleExtension);
      }
    }

    setCurrentUrl() {  
        if (!this.period) {
          this.globals.currentUrl = {
            valueFi: '/fi/' + this.getKebabCase(this.educationType.name.valueFi) + '/' + this.id,
            valueEn: '/en/' + this.getKebabCase(this.educationType.name.valueEn) + '/' + this.id,
            valueSv: '/sv/' + this.getKebabCase(this.educationType.name.valueSv) + '/' + this.id,
          };     
        } else {
          this.globals.currentUrl = {
            valueFi: '/fi/' + this.getKebabCase(this.educationType.name.valueFi) + '/' + this.id + '?period=' + this.period,
            valueEn: '/en/' + this.getKebabCase(this.educationType.name.valueEn) + '/' + this.id + '?period=' + this.period,
            valueSv: '/sv/' + this.getKebabCase(this.educationType.name.valueSv) + '/' + this.id + '?period=' + this.period
          };     
        }
     
      this.globals.createLinkForCanonicalURL();
    }

    /**
     * Päätellääm koulutus kaksiportaiseksi, jos se sisältää ainakin yhden 
     * BA-tasoisen ja ainakin yhden muu-tasoisen tutkinnon.
     * @param units 
     */
    isTwoCycleDegree(units: any[]) {
      
        let bachelor = false;
        let other = false;
        units.forEach(unit => {
          if (unit.educationLevel != null) {
            if(unit.educationLevel == 'BA') {
              bachelor = true;
            } else {
              other = true;
            }
          }
        });
        //console.log(bachelor);
        return bachelor && other;
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterializeModule } from 'angular2-materialize';
import { NgxMdModule as MarkdownModule } from 'ngx-md';

import { SharedModule } from '../shared/shared.module';
import { FrontPageComponent } from './front-page.component';
import { FrontPageRoutingModule } from './front-page-routing.module';



@NgModule({
  imports: [
    CommonModule,
    MaterializeModule,
    MarkdownModule.forRoot(),
    SharedModule,
    FrontPageRoutingModule
  ],
  declarations: [
    FrontPageComponent
  ],
  providers: [],
  exports: [
    FrontPageComponent
  ]
})
export class FrontPageModule { }

import { Component } from '@angular/core';
import * as _ from 'lodash';

// @Component({})
export class Utils {

  getKebabCase(string: string) {
    return _.kebabCase(string);
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EducationTypeComponent } from './education-type.component';


const educationTypeRoutes: Routes = [
    // { path: 'organisation', component: OrganisationListComponent },
    // { path: 'organisation/:id', component: OrganisationPageComponent }
    { path: ':lang/:organisation/:id/e', component: EducationTypeComponent }
   /* { path: ':lang/:koulutustyyppi/:organisaatioyksikko/:id', component: UnitComponent },
    { path: ':lang/:koulutustyyppi/:organisaatioyksikko/:anything/:id', component: UnitComponent }*/
];

@NgModule({
    imports: [
        RouterModule.forChild(educationTypeRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class EducationTypeRoutingModule { }

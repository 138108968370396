import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module'
import { MaterializeModule } from 'angular2-materialize';
import { RouterModule, Routes } from '@angular/router';
import { ArchiveComponent } from './archive.component';
import { OrganisationService } from '../organisation/organisation.service';
import { UnitModule } from '../unit/unit.module';
import { EducationDescriptionModule } from '../education-description/education-description.module';



const routes: Routes = [
  // { path: ':lang/arkisto/:year', component: ArchiveComponent },
   { path: ':lang/archive/:year', component: ArchiveComponent }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        SharedModule,
        EducationDescriptionModule,
        MaterializeModule
    ],
    exports: [
        RouterModule
    ],
    providers: [OrganisationService], // service
    declarations: [
      ArchiveComponent
    ]
})
export class ArchiveRoutingModule { }

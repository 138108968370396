import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Globals } from '../shared/globals';
import { environment } from '../../environments/environment';
import { flatMap, map } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { Title } from '@angular/platform-browser';
import { OrganisationService } from '../organisation/organisation.service';
import { Organisation } from '../organisation/organisation';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-front-page',
  templateUrl: './front-page.component.html',
  styleUrls: ['./front-page.component.css']
})
export class FrontPageComponent implements OnInit, OnDestroy {

  organisation: Organisation;
  errorMessage: any;
  isLoaded = false;
  period: string;
  langChangedSubscription: any;
  curriculumPeriodSubscription: any;
  private subscription: Subscription = new Subscription();

  constructor(
    private globals: Globals,
    private titleService: Title,
    private route: ActivatedRoute,
    private organisationService: OrganisationService
  ) { }

 
  ngOnInit() {
    this.subscription.add(combineLatest(this.route.paramMap, this.route.queryParamMap).pipe(
      map(params => ({     
        period: params[1].get('period')
      })),
      // haetaan jakso
      flatMap(params => { 
        this.period=params.period;
        return this.organisationService.getOrganisation(environment.frontPageOrganisationIndex);
      })).subscribe( organisation => {
        this.organisation = organisation;
        this.setTitle();
        
        this.isLoaded = true;
        this.setUrl();
      },
      error => console.log(<any>error)
      ));

      // tällä alustetaan kielivalinta sovellukselle urlista
    this.subscription.add(this.route.params.subscribe(params => {
      if (_.isEqual(params['lang'], 'fi')) {
        this.globals.lang = 'fi'
      }
      if (_.isEqual(params['lang'], 'en')) {
        this.globals.lang = 'en'
      }
      if (_.isEqual(params['lang'], 'sv')) {
        this.globals.lang = 'sv'
      }

  }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setTitle() {
    if (this.period) {
      this.titleService.setTitle(this.globals.getLocalizedValue(this.organisation.name) + environment.titleExtension + ' ' + this.period);
    } else {
      this.titleService.setTitle(this.globals.getLocalizedValue(this.organisation.name) + environment.titleExtension);
    }
  }

  setUrl() {
    if (this.isLoaded) {
      if (!this.period) {
        this.globals.currentUrl = {
          valueFi: '/fi/index', 
          valueEn: '/en/index',
          valueSv: '/sv/index'
        };
      } else {            
        this.globals.currentUrl = {
          valueFi: '/fi/index'+ '?period=' + this.period,
          valueEn: '/en/index' + '?period=' + this.period,
          valueSv: '/sv/index'+ '?period=' + this.period
        }
      }
    }
    // asetetaan canonical
    this.globals.createLinkForCanonicalURL();
  }

}

import { Component, Input, OnInit, OnChanges, SimpleChanges  } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';

import { Globals } from './../shared/globals';


import * as _ from 'lodash';

import { Programme } from '../peppi-lum/programme';
import { TextService } from '../shared/text.service';
import { LocalizedString } from '../course/localized-string';
// import { MarkdownService } from 'ngx-md';


@Component({
  selector: 'programme-unit-mobile',
  styleUrls: ['./programme.component.css'],
  template: `
  <div *ngFor="let unit of units">

        <div *ngIf="includesLearningPath(unit)" [ngClass]="{'study-module': unit.type === 'STUDY_MODULE', 'programme-item offering': unit.type === 'OFFERING',
        'category': unit.type === 'CATEGORY', 'programme-item course-unit': unit.type === 'COURSE_UNIT'}">

        <div class="programme-item" [ngClass]="{
          'study-module-item': unit.type === 'STUDY_MODULE',
          'offering-item': unit.type === 'OFFERING',
          'category-item': unit.type === 'CATEGORY',
          'course-unit-item': unit.type === 'COURSE_UNIT'}">

            <div *ngIf="creditPoints" class="programme-item-elem" [ngClass]="{
              'study-module-item': unit.type === 'STUDY_MODULE',
              'offering-item': unit.type === 'OFFERING',
              'category-item': unit.type === 'CATEGORY',
              'course-unit-item': unit.type === 'COURSE_UNIT'}">

              <a *ngIf="unit?.type === 'STUDY_MODULE'"
              routerLink="/{{globals.lang}}/{{globals.getLocalizedStudyModule()}}/{{unit?.code}}/{{unit?.id}}" [queryParams]="{period: period}">
                <!-- decimal pipes-->
                <span *ngIf="unit.maxCredits!=unit.minCredits">        
                    <span *ngIf="unit.minCredits % 1 != 0 || unit.maxCredits % 1 != 0">
                        <span *ngIf="unit.minCredits % 1 != 0">{{globals.getLocalizedValueForTitle(unit.name)}}, {{unit.minCredits | number: '1.1-1'}}</span>
                        <span *ngIf="unit.minCredits % 1 == 0">{{globals.getLocalizedValueForTitle(unit.name)}}, {{unit.minCredits}}</span>
                        <span>-</span>
                        <span *ngIf="unit.maxCredits % 1 != 0">{{unit.maxCredits | number: '1.1-1'}}</span>
                        <span *ngIf="unit.maxCredits % 1 == 0">{{unit.maxCredits}}</span>
                    </span>
                    <span *ngIf="unit.minCredits % 1 == 0 || unit.maxCredits % 1 == 0">{{globals.getLocalizedValueForTitle(unit.name)}}, {{unit.minCredits}}-{{unit.maxCredits}}</span>
                </span>
                <span *ngIf="unit.maxCredits==unit.minCredits">
                    <span *ngIf="unit.minCredits % 1 != 0">{{globals.getLocalizedValueForTitle(unit.name)}}, {{unit.minCredits | number: '1.1-1'}}</span>                  
                    <span *ngIf="unit.minCredits % 1 == 0">{{globals.getLocalizedValueForTitle(unit.name)}}, {{unit.minCredits}}</span>
                </span> 
                <!--decimal pipes end-->
                <span> {{globals.getLocalizedValue(creditPoints)}}</span>
              </a>

              <a *ngIf="unit?.type === 'CATEGORY'"
              routerLink="/{{globals.lang}}/{{globals.getLocalizedCategory()}}/{{unit?.code}}/{{unit?.id}}" [queryParams]="{period: period}">
                <!-- decimal pipes-->
                <span *ngIf="unit.maxCredits!=unit.minCredits">        
                    <span *ngIf="unit.minCredits % 1 != 0 || unit.maxCredits % 1 != 0">
                        <span *ngIf="unit.minCredits % 1 != 0">{{globals.getLocalizedValueForTitle(unit.name)}}, {{unit.minCredits | number: '1.1-1'}}</span>
                        <span *ngIf="unit.minCredits % 1 == 0">{{globals.getLocalizedValueForTitle(unit.name)}}, {{unit.minCredits}}</span>
                        <span>-</span>
                        <span *ngIf="unit.maxCredits % 1 != 0">{{unit.maxCredits | number: '1.1-1'}}</span>
                        <span *ngIf="unit.maxCredits % 1 == 0">{{unit.maxCredits}}</span>
                    </span>
                    <span *ngIf="unit.minCredits % 1 == 0 || unit.maxCredits % 1 == 0">{{globals.getLocalizedValueForTitle(unit.name)}}, {{unit.minCredits}}-{{unit.maxCredits}}</span>
                </span>
                <span *ngIf="unit.maxCredits==unit.minCredits">
                    <span *ngIf="unit.minCredits % 1 != 0">{{globals.getLocalizedValueForTitle(unit.name)}}, {{unit.minCredits | number: '1.1-1'}}</span>                  
                    <span *ngIf="unit.minCredits % 1 == 0">{{globals.getLocalizedValueForTitle(unit.name)}}, {{unit.minCredits}}</span>
                </span> 
                <!--decimal pipes end-->
                <span> {{globals.getLocalizedValue(creditPoints)}}</span>
              </a>

              <a *ngIf="unit?.type === 'OFFERING'"
              routerLink="/{{globals.lang}}/{{globals.getLocalizedOffering()}}/{{unit?.code}}/{{unit?.id}}" [queryParams]="{period: period}">
                <!-- decimal pipes-->
                <span *ngIf="unit.maxCredits!=unit.minCredits">        
                    <span *ngIf="unit.minCredits % 1 != 0 || unit.maxCredits % 1 != 0">
                        <span *ngIf="unit.minCredits % 1 != 0">{{globals.getLocalizedValueForTitle(unit.name)}}, {{unit.minCredits | number: '1.1-1'}}</span>
                        <span *ngIf="unit.minCredits % 1 == 0">{{globals.getLocalizedValueForTitle(unit.name)}}, {{unit.minCredits}}</span>
                        <span>-</span>
                        <span *ngIf="unit.maxCredits % 1 != 0">{{unit.maxCredits | number: '1.1-1'}}</span>
                        <span *ngIf="unit.maxCredits % 1 == 0">{{unit.maxCredits}}</span>
                    </span>
                    <span *ngIf="unit.minCredits % 1 == 0 || unit.maxCredits % 1 == 0">{{globals.getLocalizedValueForTitle(unit.name)}}, {{unit.minCredits}}-{{unit.maxCredits}}</span>
                </span>
                <span *ngIf="unit.maxCredits==unit.minCredits">
                    <span *ngIf="unit.minCredits % 1 != 0">{{globals.getLocalizedValueForTitle(unit.name)}}, {{unit.minCredits | number: '1.1-1'}}</span>                  
                    <span *ngIf="unit.minCredits % 1 == 0">{{globals.getLocalizedValueForTitle(unit.name)}}, {{unit.minCredits}}</span>
                </span> 
                <!--decimal pipes end-->
                <span> {{globals.getLocalizedValue(creditPoints)}}</span>
              </a>

              <a *ngIf="unit?.type === 'COURSE_UNIT'"
              routerLink="/{{globals.lang}}/{{globals.getLocalizedCourse()}}/{{unit?.code}}/{{unit?.id}}" [queryParams]="{period: period}">
                <!-- decimal pipes-->
                <span *ngIf="unit.maxCredits!=unit.minCredits">        
                    <span *ngIf="unit.minCredits % 1 != 0 || unit.maxCredits % 1 != 0">
                        <span *ngIf="unit.minCredits % 1 != 0">{{globals.getLocalizedValueForTitle(unit.name)}}, {{unit.minCredits | number: '1.1-1'}}</span>
                        <span *ngIf="unit.minCredits % 1 == 0">{{globals.getLocalizedValueForTitle(unit.name)}}, {{unit.minCredits}}</span>
                        <span>-</span>
                        <span *ngIf="unit.maxCredits % 1 != 0">{{unit.maxCredits | number: '1.1-1'}}</span>
                        <span *ngIf="unit.maxCredits % 1 == 0">{{unit.maxCredits}}</span>
                    </span>
                    <span *ngIf="unit.minCredits % 1 == 0 || unit.maxCredits % 1 == 0">{{globals.getLocalizedValueForTitle(unit.name)}}, {{unit.minCredits}}-{{unit.maxCredits}}</span>
                </span>
                <span *ngIf="unit.maxCredits==unit.minCredits">
                    <span *ngIf="unit.minCredits % 1 != 0">{{globals.getLocalizedValueForTitle(unit.name)}}, {{unit.minCredits | number: '1.1-1'}}</span>                  
                    <span *ngIf="unit.minCredits % 1 == 0">{{globals.getLocalizedValueForTitle(unit.name)}}, {{unit.minCredits}}</span>
                </span> 
                <!--decimal pipes end-->
                <span> {{globals.getLocalizedValue(creditPoints)}}</span>
              </a>
              <span *ngIf="this.selection === 1">
                <span *ngFor="let plan of unit?.accomplishmentPlanYearList; let i = index">
                  <span *ngIf="plan > 0">
                    <span>,</span><span class="plan">{{i+1}}.{{globals.getAcademicYear()}}</span>
                  </span>
                </span>
              </span>

              <span *ngIf="this.selection === 2">
                <span *ngFor="let plan of unit?.accomplishmentPlanSemesterList; let i = index">
                  <span *ngIf="plan > 0">
                    <span>, </span><span class="plan">{{roundUp(((i+1)/2), 1) }}.{{(i+1)%2 > 0 ? globals.getAutumn() : globals.getSpring()}}</span>
                  </span>
                </span>
              </span>

              <span *ngIf="this.selection === 3">
                <span *ngFor="let plan of filterAccomplishmentPlanPeriodList(unit?.accomplishmentPlanPeriodList); let i = index">
                  <span *ngIf="plan > 0">
                    <span>, </span><span class="plan">{{i+1}}.p</span>
                  </span>
                </span>
              </span>

              <span class="teached-in-english" *ngIf="globals.lang==='en' && unit?.teachedInEnglish">(in English)</span>


            </div>

           <!-- <div class="programme-item-elem" [ngClass]="{
              'study-module-centered': unit.type === 'STUDY_MODULE',
              'offering-centered': unit.type === 'OFFERING',
              'category-centered': unit.type === 'CATEGORY',
              'course-unit-centered': unit.type === 'COURSE_UNIT'}">
              {{unit.minCredits}}</div>-->
            <div>
          </div>

        </div>

        <programme-unit-mobile [unit]="unit.children" [selection]="this.selection" [creditPoints]="this.creditPoints" [year]="this.selectedSchoolYear" [learning-path]="this.learningPath" [period-count]="this.periodCount" [period]="this.period">
        </programme-unit-mobile>

  </div>
      `
})
export class ProgrammeUnitMobileComponent implements OnInit {
  @Input('unit') units: Programme[];
  @Input('selection') selection: number;
  @Input() creditPoints: LocalizedString;
  @Input('year') selectedSchoolYear: number;
  @Input('learning-path') learningPath : any;
  @Input('period') period: string;
  @Input('period-count') periodCount: any;
  // creditPoints: LocalizedString;
  // creditPoints =  {valueFi: 'op', valueEn: 'ETCS', valueSv: ''};
  errorMessage: string;

  constructor(
    private globals: Globals,
    private textService: TextService
  ) {}

  ngOnInit(): void {
    /*this.textService.getText('credit.points').subscribe(
      value => this.creditPoints = value,
      error => this.errorMessage = <any>error
    );*/
  }
  ngOnChanges(changes: SimpleChanges): void {
    //console.log("value changed", this.periodCount);
  }

  filterAccomplishmentPlanSemesterList(accomplishmentPlanSemesterList) {
    return accomplishmentPlanSemesterList.slice(this.selectedSchoolYear - 1, accomplishmentPlanSemesterList.length);
  }
  filterAccomplishmentPlanList(accomplishmentPlanList) {
    return accomplishmentPlanList.slice(this.selectedSchoolYear - 1, accomplishmentPlanList.length);
  }
  filterAccomplishmentPlanPeriodList(accomplishmentPlanPeriodList) {
    //console.log(this.selectedSchoolYear  + " " + this.periodCount);
    return accomplishmentPlanPeriodList.slice(this.sum(this.periodCount.slice(0, this.selectedSchoolYear)), this.sum(this.periodCount.slice(0, this.selectedSchoolYear+1)));
    
  }
  sum(list: any): any {
    const sum = list.reduce((partialSum, a) => partialSum + a, 0);
    //console.log("summa: " +  sum);
    return sum;
  }
  includesLearningPath(unit) {
    // palautetaan true, jos ei opintopolkuja valu
    return this.learningPath ? unit.learningPathIds.includes(this.learningPath) : true;
  }

  // precision is 10 for 10ths, 100 for 100ths, etc.
  roundUp(num, precision) {
    return Math.ceil(num * precision) / precision
  }

}

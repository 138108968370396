import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LottaApiService } from '../lotta-api/lotta-api.service';

@Injectable()
export class StudyModuleService {

  endpoint: string = 'study-module';

  constructor(private api: LottaApiService) { }

  getStudyModule(id: string): Observable<Object> {
    return this.api.getOne<Object>(this.endpoint, id);
  }

  getType(id: string): Observable<any[]> {
    return this.api.getSubList<any>('lu', id);
  }

}

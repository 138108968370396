import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.analyticsIcluded) {
  // google site verification
  const meta = document.createElement('meta');
  meta.setAttribute('name', 'google-site-verification');
  meta.setAttribute('content', '2uEg3ikzb_gvwinTRyGglWw-Z-ivBkG87aOAsMPIolY');
  document.head.appendChild(meta);
  // add Google Analytics script to <head>
  const script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('src', environment.analytics);
  document.head.appendChild(script);
}

if (environment.production) {
  enableProdMode();
}

const icon = document.createElement('link');
icon.setAttribute('rel', 'icon');
icon.setAttribute('type', 'image/x-icon');
icon.setAttribute('href', environment.favicon);
document.head.appendChild(icon);

platformBrowserDynamic().bootstrapModule(AppModule);

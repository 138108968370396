import { Injectable, EventEmitter, Inject } from '@angular/core';
import { LocalizedString } from '../course/localized-string';
import * as _ from 'lodash';
import { environment } from '../../environments/environment';
import { Name } from '../organisation/name';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn:  'root'
})
export class Globals {
  lang = environment.primaryLang;
  // https://stackoverflow.com/questions/34700438/global-events-in-angular
  langChanged: EventEmitter<string>; // global event
  schoolYearChanged: EventEmitter<number>;
  curriculumPeriodChanged: EventEmitter<string>;
  /*primaryLanguage = AppConfig.PRIMARY_LANGUAGE;
  secondaryLanguage = AppConfig.SECONDARY_LANGUAGE;
  tertiaryLanguage = AppConfig.TERTIARY_LANGUAGE*/
  languages = [environment.primaryLang, environment.secondaryLang, environment.tertiaryLang];
  spring = {valueFi: 'k', valueEn: 's', valueSv: 'v'};
  autumn = {valueFi: 's', valueEn: 'a', valueSv: 'h'};
  academicYear = {valueFi: 'lv', valueEn: 'ay', valueSv: 'lå'};
  // tämä on testi
  currentUrl: LocalizedString = {valueFi: '', valueEn: '', valueSv: ''};;
  selectedSchoolYear: number;
  selectedCurriculumPeriod: string;

  private link: HTMLLinkElement;

  constructor(
    @Inject(DOCUMENT) private document
  ) {
    this.langChanged = new EventEmitter();
    this.schoolYearChanged = new EventEmitter();
    this.curriculumPeriodChanged = new EventEmitter(); 
  //  this.currentUrl = {valueFi: '/', valueEn: '/', valueSv: '/'};
  //  this.createLinkForCanonicalURL();
  }

  setLang(value: string): void {
   // console.log('lang changed');
    this.lang = value;
    this.langChanged.emit(value);
    //muutetaan canonical url keskitetysti tässä
   // console.log('kutsuttiin canonicalia globalsissta');
   // this.createLinkForCanonicalURL();
  }

  createLinkForCanonicalURL() {
    
    if (this.link === undefined) {
      this.link = this.document.createElement('link');
      this.link.setAttribute('rel', 'canonical');
      this.document.head.appendChild(this.link);
    }
    
    this.link.setAttribute('href', window.location.protocol +'//'+ window.location.host + this.getCurrentUrl(this.lang) );
    //console.log('canonicalUrl: ' + window.location.protocol +'//'+ window.location.host + this.getCurrentUrl(this.lang));
  }

  /*getSelectedSchoolYear(): number {
    return this.selectedSchoolYear;
  }*/

  setSelectedSchoolYear(value: number): void {
    this.selectedSchoolYear = value;
    this.schoolYearChanged.emit(value);
  }

  setSelectedCurriculumPeriod(value: string): void {
    this.selectedCurriculumPeriod = value;
    this.curriculumPeriodChanged.emit(value);
  }

  // tätä käytetään vain organisaatioiden kanssa, koska niillä on määritelty nimi aina myös englanniksi
  sortByName(list: Array<Name>, lang: string) {
    if ( _.isEqual(lang, 'fi')) {
      list.sort((a, b) => {
        if (a.name.valueFi < b.name.valueFi) {
          return -1;
        } else if (a.name.valueFi > b.name.valueFi) {
         return 1;
        } else {
          return 0;
        }
      });
    }
    if ( _.isEqual(lang, 'en')) {
      list.sort((a, b) => {
        if (a.name.valueEn < b.name.valueEn) {
          return -1;
        } else if (a.name.valueEn > b.name.valueEn) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    if ( _.isEqual(lang, 'sv')) {
      list.sort((a, b) => {
        if (a.name.valueSv < b.name.valueSv) {
          return -1;
        } else if (a.name.valueSv > b.name.valueSv) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }

  sortByName2(list: Array<Name>, lang: string) {
    if ( _.isEqual(lang, 'fi')) {
      list.sort((a, b) => {
        if (a.name.valueFi < b.name.valueFi) {
          return -1;
        } else if (a.name.valueFi > b.name.valueFi) {
         return 1;
        } else {
          return 0;
        }
      });
    }
    if ( _.isEqual(lang, 'en')) {
      list.sort((a, b) => {
        if (_.isEqual(a.name.valueEn, '') || _.isEqual(b.name.valueEn, '')) {
          if (_.isEqual(a.name.valueEn, '') && _.isEqual(b.name.valueEn, '')) {
            // oletuskieli suomi
            if (_.isEqual(environment.primaryLang, 'fi')) {
              if (a.name.valueFi < b.name.valueFi) {
                return -1;
              } else if (a.name.valueFi > b.name.valueFi) {
                return 1;
              } else {
                return 0;
              }
            }
            // oletuskieli ruotsi
            if (_.isEqual(environment.primaryLang, 'sv')) {
              if (a.name.valueSv < b.name.valueSv) {
                return -1;
              } else if (a.name.valueSv > b.name.valueSv) {
                return 1;
              } else {
                return 0;
              }
            }
          }
          if (_.isEqual(a.name.valueEn, '')) {
            // oletuskieli suomi
            if (_.isEqual(environment.primaryLang, 'fi')) {
              if (a.name.valueFi < b.name.valueEn) {
                return -1;
              } else if (a.name.valueFi > b.name.valueEn) {
                return 1;
              } else {
                return 0;
              }
            }
            // oletuskieli ruotsi
            if (_.isEqual(environment.primaryLang, 'sv')) {
              if (a.name.valueSv < b.name.valueEn) {
                return -1;
              } else if (a.name.valueSv > b.name.valueEn) {
                return 1;
              } else {
                return 0;
              }
            }
          }
          if (_.isEqual(b.name.valueEn, '')) {
            // oletuskieli suomi
            if (_.isEqual(environment.primaryLang, 'fi')) {
              if (a.name.valueEn < b.name.valueFi) {
                return -1;
              } else if (a.name.valueEn > b.name.valueFi) {
                return 1;
              } else {
                return 0;
              }
            }
            // oletuskieli ruotsi
            if (_.isEqual(environment.primaryLang, 'sv')) {
              if (a.name.valueEn < b.name.valueSv) {
                return -1;
              } else if (a.name.valueEn > b.name.valueSv) {
                return 1;
              } else {
                return 0;
              }
            }
          }
        } else {
          if (a.name.valueEn < b.name.valueEn) {
            return -1;
          } else if (a.name.valueEn > b.name.valueEn) {
            return 1;
          } else {
            return 0;
          }
        }
      });
    }
    if ( _.isEqual(lang, 'sv')) {
      list.sort((a, b) => {
        if (a.name.valueSv < b.name.valueSv) {
          return -1;
        } else if (a.name.valueSv > b.name.valueSv) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }

  getLocalizedValue(value: LocalizedString): string {
    if ( _.isEqual(this.lang, 'fi') ) {
      return value.valueFi;
    }
    if ( _.isEqual(this.lang, 'en') ) {
      return value.valueEn;
    }
    if ( _.isEqual(this.lang, 'sv') ) {
      return value.valueSv;
    }
  }

  getLocalizedNavigationItem(value): string {
    if ( _.isEqual(this.lang, 'fi') ) {
      return value.valueFi;
    }
    if ( _.isEqual(this.lang, 'en') ) {
      return value.valueEn;
    }
    if ( _.isEqual(this.lang, 'sv') ) {
      return value.valueSv;
    }
  }

  getLocalizedValueForTitle(value: LocalizedString): string {
    if ( _.isEqual(this.lang, 'fi') ) {
      return value.valueFi;
    }
    // jos engl. nimikenttä on tyhjä, näytetään jaksoilla suomenkielinen (=pääkielinen) nimi
    if ( _.isEqual(this.lang, 'en') ) {
      if (_.isEqual(value.valueEn, '')) {
        if (_.isEqual(environment.primaryLang, 'fi')) {
          return value.valueFi;
        }
        if (_.isEqual(environment.primaryLang, 'sv')) {
          return value.valueSv;
        }
      } else {
        // kun nimikenttä ei ole tyhjä
        return value.valueEn;
      }
    }
    // svenska
    if ( _.isEqual(this.lang, 'sv') ) {
      return value.valueSv;
    }
  }

  getLocalizedValueIfAny(value: LocalizedString): string {
    if ( _.isEqual(this.lang, 'fi') ) {
      if (_.isEqual(value.valueFi, '')) {
          return value.valueEn;
      } else {
        return value.valueFi;
      }
    }

    if ( _.isEqual(this.lang, 'en') ) {
      if (_.isEqual(value.valueEn, '')) {
        return (!_.isEqual(value.valueFi, '') ? value.valueFi : value.valueSv);
      } else {
        return value.valueEn;
      }
    }

    if ( _.isEqual(this.lang, 'sv') ) {
      if (_.isEqual(value.valueSv, '')) {
          return value.valueEn;
      } else {
        return value.valueSv;
      }
    }
   /* if ( _.isEqual(this.lang, 'fi') ) {
      if (_.isEqual(value.valueEn, '') && !_.isEqual(AppConfig.PRIMARY_LANGUAGE, 'fi')) {
        if (_.isEqual(AppConfig.PRIMARY_LANGUAGE, 'en')) {
          return value.valueEn;
        }
        if (_.isEqual(AppConfig.PRIMARY_LANGUAGE, 'sv')) {
          return value.valueSv;
        }
      } else {
        return value.valueFi;
      }
    }
    if ( _.isEqual(this.lang, 'sv') ) {
      if (_.isEqual(value.valueEn, '') && !_.isEqual(AppConfig.PRIMARY_LANGUAGE, 'sv')) {
        if (_.isEqual(AppConfig.PRIMARY_LANGUAGE, 'fi')) {
          return value.valueFi;
        }
        if (_.isEqual(AppConfig.PRIMARY_LANGUAGE, 'en')) {
          return value.valueEn;
        }
      } else {
        return value.valueSv;
      }
    }
    if ( _.isEqual(this.lang, 'en') ) {
      if (_.isEqual(value.valueEn, '') && !_.isEqual(AppConfig.PRIMARY_LANGUAGE, 'en')) {
        if (_.isEqual(AppConfig.PRIMARY_LANGUAGE, 'fi')) {
          return value.valueFi;
        }
        if (_.isEqual(AppConfig.PRIMARY_LANGUAGE, 'sv')) {
          return value.valueSv;
        }
      } else {
        return value.valueEn;
      }
    }*/
  }

  /**
   * For URL purposes
  */
 getDegreeProgramme(): string {
  if ( _.isEqual(this.lang, 'fi')) {
    return 'ohjelma';
  }
  if ( _.isEqual(this.lang, 'en') ) {
    return 'programme';
  }
  if ( _.isEqual(this.lang, 'sv') ) {
    return 'program';
  }
 }
  getLocalizedStudyModule(): string {
    if ( _.isEqual(this.lang, 'fi')) {
      return 'opintokokonaisuus';
    }
    if ( _.isEqual(this.lang, 'en') ) {
      return 'study-module';
    }
    if ( _.isEqual(this.lang, 'sv') ) {
      return 'studiehelhet';
    }
  }

  getLocalizedCategory(): string {
    if ( _.isEqual(this.lang, 'fi')) {
      return 'ryhmittely';
    }
    if ( _.isEqual(this.lang, 'en') ) {
      return 'category';
    }
    if ( _.isEqual(this.lang, 'sv') ) {
      return 'gruppering';
    }
  }

  getLocalizedOffering(): string {
    if ( _.isEqual(this.lang, 'fi')) {
      return 'erillinen-opintokokonaisuus';
    }
    if ( _.isEqual(this.lang, 'en') ) {
      return 'offering';
    }
    if ( _.isEqual(this.lang, 'sv') ) {
      return 'separat-studiehelhet';
    }
  }
  getLocalizedCourse(): string {
    if ( _.isEqual(this.lang, 'fi')) {
      return 'opintojakso';
    }
    if ( _.isEqual(this.lang, 'en') ) {
      return 'course';
    }
    if ( _.isEqual(this.lang, 'sv') ) {
      return 'kurs';
    }
  }

  getAcademicYear() {
    if ( _.isEqual(this.lang, 'fi')) {
      return this.academicYear.valueFi;
    }
    if ( _.isEqual(this.lang, 'en')) {
      return this.academicYear.valueEn;
    }
    if ( _.isEqual(this.lang, 'sv')) {
      return this.academicYear.valueSv;
    }
  }

  getAutumn() {
    if ( _.isEqual(this.lang, 'fi')) {
      return this.autumn.valueFi;
    }
    if ( _.isEqual(this.lang, 'en')) {
      return this.autumn.valueEn;
    }
    if ( _.isEqual(this.lang, 'sv')) {
      return this.autumn.valueSv;
    }
  }

  getSpring() {
    if ( _.isEqual(this.lang, 'fi')) {
      return this.spring.valueFi;
    }
    if ( _.isEqual(this.lang, 'en')) {
      return this.spring.valueEn;
    }
    if ( _.isEqual(this.lang, 'sv')) {
      return this.spring.valueSv;
    }
  }


  getCurrentUrl(lang: string) {
    if ( _.isEqual(lang, 'fi')) {
      return this.currentUrl.valueFi;
    }
    if ( _.isEqual(lang, 'en')) {
      return this.currentUrl.valueEn;
    }
    if ( _.isEqual(lang, 'sv')) {
      return this.currentUrl.valueSv;
    }
  }

  getCurrentUrlExcludingQueryParams(lang: string) {
    if ( _.isEqual(lang, 'fi')) {
      return this.currentUrl.valueFi.split('?')[0];
    }
    if ( _.isEqual(lang, 'en')) {
      return this.currentUrl.valueEn.split('?')[0];
    }
    if ( _.isEqual(lang, 'sv')) {
      return this.currentUrl.valueSv.split('?')[0];
    }
  }


  getLocalizedStudyModuleType(lang: string, type: string): string {
    if ( _.isEqual(lang, 'fi')) {
      if ( _.isEqual(type, 'CATEGORY')) {
      return 'ryhmittely';
      }
      if ( _.isEqual(type, 'STUDY_MODULE')) {
        return 'opintokokonaisuus';
      }
      if ( _.isEqual(type, 'OFFERING')) {
        return 'erillinen-opintokokonaisuus';
      }
    }
    if ( _.isEqual(lang, 'en') ) {
      if ( _.isEqual(type, 'CATEGORY')) {
        return 'category';
      }
      if ( _.isEqual(type, 'STUDY_MODULE')) {
        return 'study-module';
      }
      if ( _.isEqual(type, 'OFFERING')) {
        return 'offering';
      }
    }
    if ( _.isEqual(lang, 'sv') ) {
      if ( _.isEqual(type, 'CATEGORY')) {
        return 'category';
      }
      if ( _.isEqual(type, 'STUDY_MODULE')) {
        return 'study-module';
      }
      if ( _.isEqual(type, 'OFFERING')) {
        return 'offering';
      }
    }
  }
}


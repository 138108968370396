import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterializeModule } from 'angular2-materialize';

import { ProgrammeComponent } from './programme.component';
import { ProgrammeRoutingModule } from './programme-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CourseComponent } from '../course/course.component';
import { NgxMdModule as MarkdownModule } from 'ngx-md';
import { ProgrammeUnitComponent } from './programme-unit.component';
import { ProgrammeUnitMobileComponent } from './programme-unit-mobile.component';
import { ProgrammeService } from './programme.service';

@NgModule({
    imports: [
        CommonModule,
        MaterializeModule,
       // CourseComponent,
       SharedModule,
       MarkdownModule.forRoot(),
        ProgrammeRoutingModule
    ],
    declarations: [
        ProgrammeComponent,
        ProgrammeUnitComponent,
        ProgrammeUnitMobileComponent
       // ModalComponent
    ],
    providers: [ProgrammeService]
})
export class ProgrammeModule {}

import {Component, OnInit, Inject} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { Subscription, combineLatest } from 'rxjs';
import { Globals } from 'app/shared/globals';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html'
  })
export class NotFoundComponent implements OnInit  {

   // subscriptions
   private subscription: Subscription = new Subscription();
   period: any;
   errorMessage: any;
   
    constructor(
        private globals: Globals,
        private route: ActivatedRoute,
        private titleService: Title
    ) {
        //this.langChangedSubscription = this.globals.langChanged.subscribe(lang => this.onLangChanged(lang));
    }

    ngOnInit() {
        //console.log("Error: page not found");
       // this.titleService.setTitle('404' + environment.titleExtension);

        this.subscription.add(combineLatest(this.route.paramMap, this.route.queryParamMap).pipe(
            map(params => ({
              id: params[0].get('id'), 
              period: params[1].get('period')
            }))
          ).subscribe(params => {
            this.period = params.period;
            this.setCurrentUrl();
            this.setTitle();
          }, error => this.errorMessage = <any>error));

        this.subscription.add(this.route.params.subscribe(params => {
            if (_.isEqual(params['lang'], 'fi')) {
              this.globals.lang = 'fi'
            }
            if (_.isEqual(params['lang'], 'en')) {
              this.globals.lang = 'en'
            }
            if (_.isEqual(params['lang'], 'sv')) {
              this.globals.lang = 'sv'
            }
          }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    setTitle() {
        if (this.period) {
            '404' + environment.titleExtension + ' ' + this.period;
        } else {
            '404' + environment.titleExtension;
        }
    }
    
    setCurrentUrl() {
        if(!this.period) {
            this.globals.currentUrl = {valueFi: '/fi/404',
            valueEn: '/en/404',
            valueSv: '/sv/404'};
        } else {
            this.globals.currentUrl = {valueFi: '/fi/404' + '?period=' + this.period, 
            valueEn: '/en/404' + '?period=' + this.period,
            valueSv: '/sv/404' + '?period=' + this.period};
        }
    }

}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// import { MaterializeModule } from 'angular2-materialize';

import { MyNavbarComponent } from './my-navbar.component';

import { NavbarService } from './navbar.service';


@NgModule({
    imports: [
        CommonModule
        // MaterializeModule
    ],
    providers: [
        NavbarService
    ]
})
export class NavbarModule { }

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LottaApiService } from '../lotta-api/lotta-api.service';

@Injectable()
export class NavbarService {

  endpoint = 'navigation';

  constructor(private api: LottaApiService) { }

  getNavigationItems(): Observable<Object[]> {
    return this.api.getList<Object>(this.endpoint);
  }

  getNavigationItem(id: string): Observable<Object> {
    return this.api.getOne<Object>(this.endpoint, id);
  }

  getLinks(): Observable<Object[]> {
    return this.api.getList<Object>(this.endpoint + '/links');
  }

}

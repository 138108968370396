import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { flatMap, map } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { ProgrammeService } from './programme.service';
import { Programme } from './programme';
import { Globals } from '../shared/globals';
import * as _ from 'lodash';
import { CourseUnit } from '../course/course-unit';
import { CourseUnitRealization } from '../course/course-unit-realization';
import { TextService } from '../shared/text.service';
import { LocalizedString } from '../course/localized-string';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { MaterializeAction } from 'angular2-materialize';

@Component({
  // selector: 'degree-programme',
  templateUrl: './programme.component.html',
  styleUrls: ['./programme.component.css']
})
export class ProgrammeComponent implements OnInit, OnDestroy {

  showInitialized = false;
  programme: Programme[] = [];
  courseUnit: CourseUnit;
  courseUnitRealization: CourseUnitRealization[];
  accomplishmentPlans: any;
  selectedPlan: any;
  //showLearningPath = false;
  actions: EventEmitter<any|MaterializeAction> = new EventEmitter <any|MaterializeAction>();
  actions2: EventEmitter<any|MaterializeAction> = new EventEmitter <any|MaterializeAction>();
  actions3: EventEmitter<any|MaterializeAction> = new EventEmitter <any|MaterializeAction>();
  actions4: EventEmitter<any|MaterializeAction> = new EventEmitter <any|MaterializeAction>();
  actions5: EventEmitter<any|MaterializeAction> = new EventEmitter <any|MaterializeAction>();
  actions6: EventEmitter<any|MaterializeAction> = new EventEmitter <any|MaterializeAction>();

  isCourseUnitLoaded = false;
  isCourseUnitRealizationsLoaded = false;
  accomplishmentPlan = 1;
  selection = 1;
  programmeDescription: Object[];
  isLoaded = false;
  isAccomplishmentPlanLoaded = true;
  selectedSchoolYear = 1;
  toggle = true;

  errorMessage: string;
  showSchoolYearPlan: LocalizedString;
  showSemesterPlan: LocalizedString;
  showPeriodPlan: LocalizedString;
  showDegreeDescription: LocalizedString;
  showAccomplishmentPlan: LocalizedString;
  showLearningPaths: LocalizedString;
  academicYear: LocalizedString;
  academicYear2: LocalizedString;
  academicYear3: LocalizedString;
  academicYear4: LocalizedString;
  academicYear5: LocalizedString;
  academicYear6: LocalizedString;
  degreeDescription: LocalizedString;
  close: LocalizedString;
  fetchingData: LocalizedString;
  accomplishmentPlanNotFound: LocalizedString;
  creditPoints: LocalizedString;

  langSelection: any;
  programmeId: string;
  period: string;

  private subscription: Subscription = new Subscription();

  constructor(
     private programmeService: ProgrammeService,
     private globals: Globals,
     private route: ActivatedRoute,
     private router: Router,
     private textService: TextService,
     private titleService: Title
    ) {}

  toggleThis(param: boolean) {
    if (param) {
      this.toggle = true;
    } else {
      this.toggle = false;
    }
  }

  filterAccomplishmentPlanSemesterList(accomplishmentPlanSemesterList) {
    return accomplishmentPlanSemesterList.slice(this.selectedSchoolYear - 1, accomplishmentPlanSemesterList.length);
  }

  filterAccomplishmentPlanList(accomplishmentPlanList) {
      return accomplishmentPlanList.slice(this.selectedSchoolYear - 1, accomplishmentPlanList.length);
  }

  /*filterAccomplishmentPlanPeriodList(accomplishmentPlanPeriodList) {
    return accomplishmentPlanPeriodList.slice((this.selectedSchoolYear * 5 - 5), this.selectedSchoolYear * 5);
  }*/
  filterAccomplishmentPlanPeriodList(accomplishmentPlanPeriodList, accomplishmentPlanYearList) {
    let periodCount = Math.ceil(accomplishmentPlanPeriodList.length / accomplishmentPlanYearList.length);
    console.log(periodCount);
    return accomplishmentPlanPeriodList.slice((this.selectedSchoolYear * periodCount - periodCount), this.selectedSchoolYear * periodCount);
  }

  setAccomplishmentPlanYear(year) {
    this.selectedSchoolYear = year;
    this.selection = 3;
  }

  setAccomplishmentPlanYear2(event, year) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.setAccomplishmentPlanYear(year);
      this.actions2.emit({action:"dropdown", params:['close']}); 
    }
  }
  setAccomplishmentPlanYear3(event, year) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.setAccomplishmentPlanYear(year);
      this.actions5.emit({action:"dropdown", params:['close']}); 
    }
  }

  selectAccomplishmentPlan(plan) {
    //this.selection = 4;
    this.selectedPlan = plan;
    this.isAccomplishmentPlanLoaded = false;
    this.getAccomplishmentPlan(plan.accomplishmentPlanId);
  }

  selectAccomplishmentPlan2(event, plan) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.selectAccomplishmentPlan(plan);
      this.actions3.emit({action:"dropdown", params:['close']}); 
    }
  }
  selectAccomplishmentPlan3(event, plan) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.selectAccomplishmentPlan(plan);
      this.actions6.emit({action:"dropdown", params:['close']}); 
    }
  }

  getAccomplishmentPlan(accomplishmentPlanId: string) {
    this.subscription.add(
      //hae oikeasta paikasta
      this.programmeService.getAccomplishmentPlan(accomplishmentPlanId).subscribe(
        programme => {
          this.programme = programme;
          this.isAccomplishmentPlanLoaded = true;
      },
      error => this.errorMessage = <any>error
    ));
  }

  // pudotusvalikon valinta
  setSelection(view) {
      if (_.isEqual('schoolyear', view)) {
        this.selection = 1;
      } else if (_.isEqual('semester', view)) {
        this.selection = 2;
      } else if (_.isEqual('period', view)) {
        this.selection = 3;
      }
      this.selectedSchoolYear = 1;
  }

  setSelection2(event, view) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.setSelection(view);
      this.actions.emit({action:"dropdown", params:['close']}); 
    }
  }

  setSelection3(event, view) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.setSelection(view);
      this.actions4.emit({action:"dropdown", params:['close']}); 
    }
  }

  doNothing(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.actions.emit({action:"dropdown", params:['open']}); 
    } 
  }

  doNothing2(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.actions2.emit({action:"dropdown", params:['open']}); 
    } 
  }

  doNothing3(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.actions3.emit({action:"dropdown", params:['open']}); 
    } 
  }
  doNothing4(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.actions4.emit({action:"dropdown", params:['open']}); 
    } 
  }
  doNothing5(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.actions5.emit({action:"dropdown", params:['open']}); 
    } 
  }
  doNothing6(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.actions6.emit({action:"dropdown", params:['open']}); 
    } 
  }

  getSelection(selected) {
    if (selected === 1) {
      return this.showSchoolYearPlan;
    } else if (selected === 2) {
      return this.showSemesterPlan;
    } else if (selected === 3) {
      return this.showPeriodPlan;
    } 
  }

  getSelectedAcademicYear(year) {
    if (year === 1) {
      return this.academicYear;
    } else if (year === 2) {
      return this.academicYear2;
    } else if (year === 3) {
      return this.academicYear3;
    } else if (year === 4) {
      return this.academicYear4;
    } else if (year === 5) {
      return this.academicYear5;
    } else if (year === 6) {
      return this.academicYear6;
    }
  }

  // precision is 10 for 10ths, 100 for 100ths, etc.
  roundUp(num, precision) {
    return Math.ceil(num * precision) / precision
  }

  ngOnInit(): void {

    this.subscription.add(this.textService.getText('accomplishment.plan.show.school.year').subscribe(
      value => this.showSchoolYearPlan = value,
      error => this.errorMessage = <any>error
    ));

    this.subscription.add(this.textService.getText('accomplishment.plan.show.semester').subscribe(
      value => this.showSemesterPlan = value,
      error => this.errorMessage = <any>error
    ));

    this.subscription.add(this.textService.getText('accomplishment.plan.show.period').subscribe(
      value => this.showPeriodPlan = value,
      error => this.errorMessage = <any>error
    ));

    this.subscription.add(this.textService.getText('accomplishment.plan.school.year.1').subscribe(
      value => this.academicYear = value,
      error => this.errorMessage = <any>error
    ));
    this.subscription.add(this.textService.getText('accomplishment.plan.school.year.2').subscribe(
      value => this.academicYear2 = value,
      error => this.errorMessage = <any>error
    ));
    this.subscription.add(this.textService.getText('accomplishment.plan.school.year.3').subscribe(
      value => this.academicYear3 = value,
      error => this.errorMessage = <any>error
    ));
    this.subscription.add(this.textService.getText('accomplishment.plan.school.year.4').subscribe(
      value => this.academicYear4 = value,
      error => this.errorMessage = <any>error
    ));
    this.subscription.add(this.textService.getText('accomplishment.plan.school.year.5').subscribe(
      value => this.academicYear5 = value,
      error => this.errorMessage = <any>error
    ));
    this.subscription.add(this.textService.getText('accomplishment.plan.school.year.6').subscribe(
      value => this.academicYear6 = value,
      error => this.errorMessage = <any>error
    ));

    this.subscription.add(this.textService.getText('accomplishment.plan.show.degree.description').subscribe(
      value => this.showDegreeDescription = value,
      error => this.errorMessage = <any>error
    ));

    this.subscription.add(this.textService.getText('accomplishment.plan.show.structure').subscribe(
      value => this.showAccomplishmentPlan = value,
      error => this.errorMessage = <any>error
    ));

    this.subscription.add(this.textService.getText('degree.description').subscribe(
      value => this.degreeDescription = value,
      error => this.errorMessage = <any>error
    ));

    this.subscription.add(this.textService.getText('close').subscribe(
      value => this.close = value,
      error => this.errorMessage = <any>error
    ));

    this.subscription.add(this.textService.getText('fetching.data').subscribe(
      value => this.fetchingData = value,
      error => this.errorMessage = <any>error
    ));

    this.subscription.add(this.textService.getText('accomplishment.plan.not.found').subscribe(
      value => this.accomplishmentPlanNotFound = value,
      error => this.errorMessage = <any>error
    ));

    this.subscription.add(this.textService.getText('credit.points').subscribe(
      value => this.creditPoints = value,
      error => this.errorMessage = <any>error
    ));

    this.subscription.add(combineLatest(this.route.paramMap, this.route.queryParamMap).pipe(
      map(params => ({
        programmeId: params[0].get('id'), 
        period: params[1].get('period')
      })),
      // haetaan ajoitussuunnitelma
      flatMap(params => {
        this.programmeId = params.programmeId;
        this.period = params.period;
        this.setCurrentUrl();
        return this.programmeService.getProgrammeWithLearningUnit(this.programmeId);
      }),
      //haetaan kuvaus
      flatMap(programme => {
        this.programme = programme;
        if (this.programme.length > 0) {
          this.setTitle();
        }
        return this.programmeService.getProgrammeDescription(this.programmeId);
      }),
      flatMap(description => {
        this.programmeDescription = description;
        return this.programmeService.getAccomplishmentPlanList(this.programmeId);
      })
      ).subscribe(
        accomplishmentPlans => {
          this.accomplishmentPlans = accomplishmentPlans;
          //oletuksena näytetään listan ensimmäinen
          this.selectedPlan = this.accomplishmentPlans[0];
          this.isLoaded = true;
        },
        error => this.errorMessage = <any>error
      ));

    // tällä alustetaan kielivalinta sovellukselle urlista
    this.subscription.add(this.langSelection = this.route.params.subscribe(params => {
      if (_.isEqual(params['lang'], 'fi')) {
        this.globals.lang = 'fi'
      }
      if (_.isEqual(params['lang'], 'en')) {
        this.globals.lang = 'en'
      }
      if (_.isEqual(params['lang'], 'sv')) {
        this.globals.lang = 'sv'
      }
      },
      error => this.errorMessage = <any>error
    ));    
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setTitle() {
    if (this.period) {
      this.titleService.setTitle(this.globals.getLocalizedValue(this.programme[0].name) + environment.titleExtension + ' ' + this.period);
    } else {
      this.titleService.setTitle(this.globals.getLocalizedValue(this.programme[0].name) + environment.titleExtension);
    }
  }

  setCurrentUrl() {
    // asetetaan url
    if (!this.period) {
      this.globals.currentUrl = {
        valueFi: '/fi/ohjelma/' + this.programmeId,
        valueEn: '/en/programme/' + this.programmeId,
        valueSv: '/sv/program/' + this.programmeId
      };
    } else {
      this.globals.currentUrl = {
        valueFi: '/fi/ohjelma/' + this.programmeId + '?period=' + this.period,
        valueEn: '/en/programme/' + this.programmeId + '?period=' + this.period,
        valueSv: '/sv/program/' + this.programmeId + '?period=' + this.period
      };
    }      
    this.globals.createLinkForCanonicalURL(); 
  }

}

import { Component, Input, Output, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { LumService } from '../peppi-lum/lum.service';
import { Globals } from '../shared/globals';
import * as _ from 'lodash';
import {MaterializeAction} from 'angular2-materialize';
import { CourseUnit } from './course-unit';
import { CourseUnitRealization } from './course-unit-realization';
import { CourseService } from './course.service';
import { LottaApiService } from '../lotta-api/lotta-api.service';
import { LocalizedString } from './localized-string';
import { MarkdownComponent } from '../shared/markdown.component'
import { TextService } from '../shared/text.service';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-realizations',
  templateUrl: './realization.component.html',
  styleUrls: ['./course.component.css']
})
export class RealizationComponent implements OnInit {

  @Input() id: string;
  @Input() realizations: CourseUnitRealization[];

  actions: EventEmitter<any|MaterializeAction> = new EventEmitter <any|MaterializeAction>();
  actions2: EventEmitter<any|MaterializeAction> = new EventEmitter <any|MaterializeAction>();

  errorMessage: string;
  // Otsikot
  showMore: LocalizedString;
  showLess: LocalizedString;

  showAllEvents = false;
  showAllRealizationEvents: boolean[] = [];
  showAllGroupEvents: boolean[] = [];

  myDate = new Date();

  constructor(
    private globals: Globals,
    private textService: TextService
  ) {}

  ngOnInit(): void {



    this.textService.getText('show.more').subscribe(
      value => this.showMore = value,
      error => this.errorMessage = <any>error
    );

    this.textService.getText('show.less').subscribe(
      value => this.showLess = value,
      error => this.errorMessage = <any>error
    );

  }

  showGroupEvents(index: number): boolean {
    return this.showAllGroupEvents[index];
  }

  showRealizationEvents(index: number): boolean {
    return this.showAllRealizationEvents[index];
  }

  collapse(event, j) {
    if (event.key === "Enter") {
      //event.stopPropagation();
      this.actions.emit({action:"collapsible", params:['open', j]}); 
     // this.actions2.emit({action:"collapsible", params:['open', j]}); 
    }
  }

  collapse2(event, j) {
    if (event.key === "Enter") {
      event.stopPropagation();
      this.actions2.emit({action:"collapsible", params:['open', j]}); 
    }
  }

  toggleRealizationEvents(index: number) {
    this.showAllRealizationEvents[index] ?
      this.showAllRealizationEvents[index] = false : this.showAllRealizationEvents[index] = true;
  }

  toggleRealizationEvents2(event, index: number) {
    if (event.key === "Enter") {
      event.stopPropagation();
      this.toggleRealizationEvents(index);
    }
  }

  toggleGroupEvents(index: number) {
    this.showAllGroupEvents[index] ? this.showAllGroupEvents[index] = false : this.showAllGroupEvents[index] = true;
  }

  toggleGroupEvents2(event, index: number) {
    if (event.key === "Enter") {
      event.stopPropagation();
      this.toggleGroupEvents(index);
    }
  }

}


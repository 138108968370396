import { Component, Input, OnInit, OnChanges, SimpleChanges  } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Globals } from './../shared/globals';

@Component({
  selector: 'structure-unit',
  templateUrl: './structure-unit.component.html',
  styleUrls: ['./study-module.component.css']
})
export class StructureUnitComponent   {

  @Input('unit') units: any;
  @Input('period') period: string;

  constructor(
    private globals: Globals
  ) {}

}

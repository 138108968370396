import { Component, OnInit, OnDestroy  } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { flatMap, map } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { Globals } from '../shared/globals';
import * as _ from 'lodash';
import { CourseUnit } from './course-unit';
import { CourseUnitRealization } from './course-unit-realization';
import { CourseService } from './course.service';
import { LocalizedString } from './localized-string';
import { TextService } from '../shared/text.service';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

@Component({
  selector: 'course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CourseComponent implements OnInit, OnDestroy {

  id: string;
  realizations: CourseUnitRealization[];
  pastRealizations: CourseUnitRealization[];
  course: CourseUnit;
  showTags = environment.showTags;
  isLoaded = false;
  errorMessage: string;
  showPastRealizations = false;
  // Otsikot
  realizationsHeader: LocalizedString;
  pastRealizationsHeader: LocalizedString;
  showPastRealizationsLink: LocalizedString;
  showMore: LocalizedString;
  showLess: LocalizedString;
  creditPoints: LocalizedString;

  showAllRealizationEvents: boolean[] = [];
  showAllGroupEvents: boolean[] = [];

  period: string;
  
  private subscription: Subscription = new Subscription();

  constructor(
     private courseService: CourseService,
     private globals: Globals,
     private route: ActivatedRoute,
     private router: Router,
     private textService: TextService,
     private titleService: Title
    ) { }

  ngOnInit(): void {
    
    // haetaan puuttuvia tekstejä
    this.subscription.add(this.textService.getText('realizations').subscribe(
      value => this.realizationsHeader = value,
      error => this.errorMessage = <any>error
    ));
    this.subscription.add(this.textService.getText('realizations.past').subscribe(
      value => this.pastRealizationsHeader = value,
      error => this.errorMessage = <any>error
    ));
    this.subscription.add(this.textService.getText('realizations.past.show').subscribe(
      value => this.showPastRealizationsLink = value,
      error => this.errorMessage = <any>error
    ));
    this.subscription.add(this.textService.getText('show.more').subscribe(
      value => this.showMore = value,
      error => this.errorMessage = <any>error
    ));

    this.subscription.add(this.textService.getText('show.less').subscribe(
      value => this.showLess = value,
      error => this.errorMessage = <any>error
    ));

    this.subscription.add(this.textService.getText('credit.points').subscribe(
      value => this.creditPoints = value,
      error => this.errorMessage = <any>error
    ));

    this.subscription.add(combineLatest(this.route.paramMap, this.route.queryParamMap).pipe(
      map(params => ({
        id: params[0].get('id'), 
        period: params[1].get('period')
      })),
      // haetaan jakso
      flatMap(params => {
        this.id = params.id;
        this.period = params.period;
        return this.courseService.getCourseUnit(this.id);
      }),
      flatMap(course => {
        this.course = course;
        this.setCurrentUrl();
        this.setTitle();
        return this.courseService.getCourseUnitRealization(this.id);
      })
      ).subscribe(
        realizations => {
          this.realizations = realizations;
          this.isLoaded = true;
        }, error => {
          console.log(error);
          if(!this.period) {
            this.router.navigateByUrl('/' + this.globals.lang + '/404');
          } else {
            this.router.navigateByUrl('/' + this.globals.lang + '/404' + '?period=' + this.period);
          }
        }
      ));


    // tällä alustetaan kielivalinta sovellukselle urlista
    this.subscription.add(this.route.params.subscribe(params => {
      if (_.isEqual(params['lang'], 'fi')) {
        this.globals.lang = 'fi'
      }
      if (_.isEqual(params['lang'], 'en')) {
        this.globals.lang = 'en'
      }
      if (_.isEqual(params['lang'], 'sv')) {
        this.globals.lang = 'sv'
      }
    }));

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  showRealizations(event){
    if (event.key === "Enter") {
       this.getPastRealizations();
    }
  }

  getPastRealizations(){
    this.isLoaded = false;
    //this.pastRealizations = this.courseService.getPastCourseUnitRealization(this.id);
    this.subscription.add(combineLatest(this.route.paramMap, this.route.queryParamMap).pipe(
      map(params => ({
        id: params[0].get('id')
      })),
      flatMap(params => {
        this.id = params.id;
        return this.courseService.getPastCourseUnitRealization(this.id);
      })
      ).subscribe(
        pastRealizations => {
          this.pastRealizations = pastRealizations;
          this.isLoaded = true;
          this.showPastRealizations=true; 
        }, error => {
          console.log(error);       
        }
      ));
  }
  
  setTitle() {
    if (this.period) {
      this.titleService.setTitle(this.course.code + ' ' + this.globals.getLocalizedValue(this.course.name) + environment.titleExtension + ' ' + this.period);
    } else {
      this.titleService.setTitle(this.course.code + ' ' + this.globals.getLocalizedValue(this.course.name) + environment.titleExtension);
    }
  }

  setCurrentUrl() {
    // asetetaan url
    if (!this.period) {
      this.globals.currentUrl = {
        valueFi: '/fi/opintojakso/' + this.course.code + '/' + this.id,
        valueEn: '/en/course/' + this.course.code + '/' + this.id,
        valueSv: '/sv/kurs/' + this.course.code + '/' + this.id
      };
    } else {
      this.globals.currentUrl = {
        valueFi: '/fi/opintojakso/' + this.course.code + '/' + this.id + '?period=' + this.period,
        valueEn: '/en/course/' + this.course.code + '/' + this.id + '?period=' + this.period,
        valueSv: '/sv/kurs/' + this.course.code + '/' + this.id + '?period=' + this.period
      };
    } 
    this.globals.createLinkForCanonicalURL();
  }

    showGroupEvents(index: number): boolean {
      return this.showAllGroupEvents[index];
    }

    showRealizationEvents(index: number): boolean {
      return this.showAllRealizationEvents[index];
    }

    toggleRealizationEvents(index: number) {
      this.showAllRealizationEvents[index] ?
        this.showAllRealizationEvents[index] = false : this.showAllRealizationEvents[index] = true;
    }

    toggleGroupEvents(index: number) {
      this.showAllGroupEvents[index] ? this.showAllGroupEvents[index] = false : this.showAllGroupEvents[index] = true;
    }

    evalMethod(value: LocalizedString): string {
      return this.globals.getLocalizedValue(value);
    }


}
